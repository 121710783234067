/* This file is used to assist in authenticating a user's login. */
import Cookie from "js-cookie";

let settings = fetch("/");

let apiRef = "https://api.tracsoft.com";//Live
//let apiRef = "https://6352-46153.el-alt.com/"; // Staging
//let apiRef = "https://localhost:44348";//Local
let httpHost = "";

/* Return the name of the company */
export async function getCompanyName() {
    return "TSDonate";
}

/* Call API directly from JS. */
export async function callAPI(methodType, methodUrl, formData) {
    //Make an object from the form input
    var methodData = {};
    if (formData != null) {
        for (var i = 0; i < formData.elements.length; i++) {
            if (formData.elements[i].name != "submit") {
                methodData[formData.elements[i].name] = formData.elements[i].value;
            }
        }

        //Request API call and get response
        const response = await fetch(methodUrl, {
            method: methodType,
            body: JSON.stringify(methodData),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        console.info(response);
        const result = await response.text();
        const statusCode = await response.status;

        var responseObj = {
            result: result,
            statusCode: statusCode
        };

        return responseObj;
    }
    else {
        //Request API call and get response
        const response = await fetch(methodUrl, {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const result = await response.text();
        const statusCode = await response.status;

        var responseObj = {
            result: result,
            statusCode: statusCode
        };

        return responseObj;
    }
}

export async function logError(message) {
    var errorMessage = "<!channel> " + message;
    var data = {
        "text": errorMessage
    };
    console.log(errorMessage);
    const response = await fetch('https://hooks.slack.com/services/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', {
        method: 'POST',
        body: JSON.stringify(data)
    });

    const result = await response;
    console.log(result);
}

/* Authenticate the login info and session of the user. */
export async function authenticateLogin(form) {
    var response = await callAPI("POST", apiRef + "/api/authentication/authenticate/", form);
    if (response.statusCode == 200) {
        var isPasswordExp = await isPasswordExpired(response.result);
        if (isPasswordExp == true) {
            updateDisplayForm("login", "changePassword");
            checkPasswordExpiration(response.result);
        }
        else {
            var shouldBypass = await bypassTwoFactor(response.result);
            if (!shouldBypass) {
                updateDisplayForm("login", "twoFactor");
                sendTwoFactorCode(response.result);
            }
        }
    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("loginError").innerHTML = error.message;
        console.log(error.message);
        var user = form.elements[0].value;
        if (error.message != 'User is locked out' && error.message != 'Username or password is incorrect') {
            logError(user + ' was unable to login');
        }
    }
}

/* Check if the user's password is expired. */
export async function isPasswordExpired(userId) {
    var response = await callAPI("GET", apiRef + "/api/authentication/changePassword?userId=" + userId, null);
    var statusCode = JSON.parse(response.statusCode);
    return ((statusCode == 200) ? false : true);
}

/* Check if password requires an update. */
export async function checkPasswordExpiration(userId) {
    var response = await callAPI("GET", apiRef + "/api/authentication/changePassword?userId=" + userId, null);
    //console.log("checkPasswordExpiration", response);

    document.getElementById("changePasswordUserId").value = userId;

    if (response.statusCode == 200) {
        var shouldBypass = await bypassTwoFactor(response.result);
        if (!shouldBypass) {
            updateDisplayForm("changePassword", "twoFactor");
            sendTwoFactorCode(userId);
        }
    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("changePasswordError").innerHTML = error.message;
    }
}

/* Verify if password has been successfuly updated. */
export async function verifyPasswordUpdate(form) {
    var response = await callAPI("POST", apiRef + "/api/authentication/changePassword", form);
    //console.log("verifyPasswordUpdate", response);

    if (response.statusCode == 200) {
        var shouldBypass = await bypassTwoFactor(response.result);
        if (!shouldBypass) {
            updateDisplayForm("changePassword", "twoFactor");
            sendTwoFactorCode(response.result);
        }
    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("changePasswordError").innerHTML = error.message;
    }
}

/* Generate and send the two factor code to the user's phone. */
export async function sendTwoFactorCode(userId) {
    var response = await callAPI("GET", apiRef + "/api/authentication/twoFactor?userId=" + userId, null);
    //console.log("sendTwoFactorCode", response);

    document.getElementById("twoFactorUserId").value = userId;

    if (response.statusCode == 200) {

    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("twoFactorError").innerHTML = error.message;
    }
}

/* Resend the two factor code via user input. */
export function resendTwoFactorCode() {
    var userId = document.getElementById("twoFactorUserId").value;
    sendTwoFactorCode(userId);
}

/* Verify the two factor code is correct. */
export async function verifyTwoFactorCode(form) {
    var response = await callAPI("POST", apiRef + "/api/authentication/twoFactor", form);
    //console.log("verifyTwoFactorCode", response);
    
    if (response.statusCode == 200) {
        console.log(response);
        var response = fetch("/Authentication/Authenticate?session_token=" + response.result.replace("\"", "").replace("\"", ""),
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json'
                }
            }).then(r => {
                r.text().then(r => { console.info(r); createCookie(r); window.location.replace(httpHost + "/donations"); });
            });     
    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("twoFactorError").innerHTML = error.message;
    }
}

/* Set cookie and redirect if the user can bypass the two factor authentication step. */
export async function bypassTwoFactor(userId) {
    // const responseIP = await fetch(httpHost + '/models/clientIPAddress.php', {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     }
    // });

    // const resultIP = await responseIP.text();
    // const statusCodeIP = await responseIP.status;

    // var response = await callAPI("GET", apiRef + "/api/authentication/IsIpAllowedBypass?userId=" + userId + "&ipAddress=" + resultIP, null);

    // var statusCode = JSON.parse(response.statusCode);
    // if (statusCode == 200) {
    //     var sessionId = JSON.parse(response.result);
    //     createCookie(sessionId);
    //     window.location.replace(httpHost + "/views/relatedClients.php");
    //     return true;
    // }
    // else {
    //     return false;
    // }
    return false;
}

/* Confirm email and send code to reset password. */
export async function forgotPasswordRequest(form) {
    var response = await callAPI("POST", apiRef + "/api/authentication/ForgotPasswordRequest", form);

    if (response.statusCode == 200) {
        updateDisplayForm("forgotPasswordRequest", "forgotPasswordChange");
        var email = JSON.parse(response.result);
        document.getElementById("forgotPasswordChangeEmail").value = email;
    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("forgotPwRequestError").innerHTML = error.message;
    }
}

/* Verify code and change password. */
export async function forgotPasswordChange(form) {
    console.log(form);
    var response = await callAPI("POST", apiRef + "/api/authentication/ForgotPasswordChange", form);

    if (response.statusCode == 200) {
        window.location.replace("/");
    }
    else {
        var error = JSON.parse(response.result);
        document.getElementById("forgotPwChangeError").innerHTML = error.message;
    }
}

/* Confirm the new password matches the confirm password field. */
export function matchPassword(confirmPw) {
    if (confirmPw.value != document.getElementById('newPassword').value) {
        confirmPw.setCustomValidity('Passwords do not match');// Display error message
    } else {
        confirmPw.setCustomValidity("");// Remove error message
    }
}

export function matchNewPassword(confirmPw) {
    if (confirmPw.value != document.getElementById('forgotNewPassword').value) {
        confirmPw.setCustomValidity('Passwords do not match');// Display error message
    } else {
        confirmPw.setCustomValidity("");// Remove error message
    }
}

/* Set the cookie with the session token as the value. */
export function createCookie(sessionToken) {
   var sessionId = (sessionToken.charAt(0) == "\"" && sessionToken.charAt(sessionToken.length - 1) == "\"") ? sessionToken.slice(1, -1) : sessionToken;

    var d = new Date();
    d.setTime(d.getTime() + (7 * (24 * 60 * 60 * 1000)))//90 Days -> (days * milliseconds per day)
    var expires = "expires=" + d.toUTCString();
    document.cookie = "AuthCookie=" + sessionId + ";" + expires + ";path=/";
}

/* Hide/Show next form */
export function updateDisplayForm(hideForm, showForm) {
    for (let i = 0; i < document.getElementsByClassName("error-message").length; i++) {
        document.getElementsByClassName("error-message")[i].innerHTML = "";
    }
    document.getElementsByClassName("error-message")
    document.getElementById(hideForm).children[0].reset();
    document.getElementById(hideForm).setAttribute("style", "display: none");
    document.getElementById(showForm).children[0].reset();
    document.getElementById(showForm).setAttribute("style", "display: block");
}

/* Get the server side token */
export async function GetLoggedInUserCompany() {
    let token = Cookie.get("AuthCookie");
    const response = await fetch("transactions/GetLoggedInUserCompany?sessionToken=" + token, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    const company = await response.json();
    return company;
}

/* Return the logged in user */
export async function GetLoggedInUserEmail() {
    var token = Cookie.get("AuthCookie");
    const response = await fetch("transactions/GetLoggedInUserEmail?sessionToken=" + token, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    const email = await response.json();
    return email;
}

/* Return the logged in user */
//export async function getLoggedInUser() {
//    let token = Cookie.get("AuthCookie");
//    var companyName = await GetLoggedInUserCompany();

//    var response = await callAPI("GET", apiRef + "/api/authentication/getUserBySessionToken?sessionToken=" + token + "&company=" + companyName, null);

//    if (response.statusCode != 200 || response.result == null) {
//        return null;
//    }
//    else {
//        return response.result;
//    }
//}
