import React, { Component } from 'react';
import './imagemodule.css';
import { Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import imageCompression from 'browser-image-compression';
import currentCompanyServices from '../CurrentCompanyServices';

export class ImageModule extends Component {
    static displayName = ImageModule.name;

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            editModal: false,
            imagePreview: null,
            imageDescription: null,
            editImageDescription: null,
            imageBlob: null,
            images: null,
            search: null,
            height: null,
            width: null,
            preCompression: null,
            postCompression: null,
            compressionSavings: null,
            editImageUrl: null,
            editImageId: null,
        };

        this.toggle = this.toggle.bind(this);
        this.editToggle = this.editToggle.bind(this);
        this.handleImageUpload = this.handleImageUpload.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.handleImageDescriptionChange = this.handleImageDescriptionChange.bind(this);
        this.handleEditImageDescriptionChange = this.handleEditImageDescriptionChange.bind(this);
        this.loadImages = this.loadImages.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.onPreviewLoad = this.onPreviewLoad.bind(this);
        this.editImage = this.editImage.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
    }

    onPreviewLoad(e) {
        var img = e.target;
        var height = img.naturalHeight;
        var width = img.naturalWidth;
        //console.log("Height: " + height + " Width: " + width);
        this.setState({
            height: height,
            width: width
        });
    }

    editToggle(e) {
        var idUndefined = e.id === undefined;
        if (!idUndefined) {
            this.setState({
                editModal: !this.state.editModal,
                editImageDescription: e.name,
                editImageUrl: "images/" + e.src,
                editImageId: e.id
            })
        }
        else {
            this.setState({
                editModal: !this.state.editModal,
                editImageDescription: null,
                editImageUrl: null,
                editImageId: null
            })
        }
    }

    async editImage(e) {
        await fetch("ImageModule/EditImage", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Id: this.state.editImageId,
                ImageDescription: this.state.editImageDescription
            })
        });
        this.editToggle(this);
        this.loadImages();
    }

    async deleteImage(e) {
        if (window.confirm("Are you sure you want to delete this image?")) {
            await fetch("ImageModule/DeleteImage", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Id: this.state.editImageId,
                    ImageDescription: this.state.editImageDescription
                })
            });
            this.editToggle(this);
            this.loadImages();
        }
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            imagePreview: null,
            imageDescription: null,
            imageBlob: null,
            height: null,
            width: null,
            preCompression: null,
            postCompression: null,
            compressionSavings: null
        });
    }

    async uploadImage() {
        if (this.state.imagePreview == null) {
            alert("You must upload an image.");
            return false;
        }
        else if (this.state.imageDescription == null) {
            alert("You must provide an image description.");
            return false;
        }

        let imageName = this.state.imageBlob.name;
        if (imageName.includes(";") || imageName.includes("/") || imageName.includes("?") ||
            imageName.includes(":") || imageName.includes("@") || imageName.includes("&") ||
            imageName.includes("=") || imageName.includes("+") || imageName.includes("$") || imageName.includes(",")) {

            alert('File name can not include the characters ";" | "/" | "?" | ":" | "@" | "&" | "=" | "+" | "$" | ","');
            return false;
        }

        const formData = new FormData();
        formData.append('ImageDescription', this.state.imageDescription);
        formData.append('Image', this.state.imageBlob);
        formData.append('ImageName', imageName);

        var response = await fetch('ImageModule/ImageUpload',
            {
                method: 'POST',
                body: formData
            }
        );

        //console.log(response);
        this.toggle();
        this.loadImages();
    }

    async loadImages() {
        var response = await fetch('ImageModule/RetrieveAll', {
            method: 'POST'
        });
        var images = await response.json();
        this.setState({
            images: images
        });
        //console.log(images);
    }

    handleImageDescriptionChange(e) {
        this.setState({
            imageDescription: e.target.value
        });
    }

    handleEditImageDescriptionChange(e) {
        this.setState({
            editImageDescription: e.target.value
        });
    }

    async handleSearchChange(e) {
        await this.setState({
            search: e.target.value
        });

        if (this.state.search != null) {
            if (this.state.search.length > 2) {
                this.setState({
                    images: this.state.images.filter(u => u.name.toLowerCase().includes(this.state.search.toLowerCase()))
                });
            }
            else if (this.state.search == 0) {
                this.loadImages();
            }
        }
    }

    componentDidMount() {
        if (this.state.images == null) {
            this.loadImages();
        }
        this.getComponentData();
    }

    async handleImageUpload(event) {
        const imageFile = event.target.files[0];
        //console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        //console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
        //console.log(imageFile);
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            //console.log(compressedFile);
            var preComp = (imageFile.size / 1024).toFixed(2);
            var postComp = (compressedFile.size / 1024).toFixed(2);
            this.setState({
                imageBlob: compressedFile,
                imagePreview: window.URL.createObjectURL(compressedFile),
                preCompression: `${preComp} KB`,
                postCompression: `${postComp} KB`,
                compressionSavings: `Saved ${(preComp - postComp).toFixed(2)} KB (${((preComp - postComp) / preComp * 100).toFixed(2)}%)`
            });

            //await uploadToServer(compressedFile); // write your own logic
        } catch (error) {
            //console.log(error);
        }

    }

    render() {
        let imagePreview = this.state.imagePreview != null ? <div><hr /><img onLoad={this.onPreviewLoad} id="preview" style={{ maxWidth: "100%", maxHeight: "300px" }} src={this.state.imagePreview} /></div> : null;
        let imageStats;
        let imageTypes = null;
        let search;
        let images;
        let logoColor = this.state.height == 200 && this.state.width == 289 ? 'primary' : 'disabled';
        let slideshowColor = this.state.height == 388 && this.state.width == 701 ? 'primary' : 'disabled';

        if (imagePreview != null) {
            imageStats = <div><hr />Image Information<br />Height: {this.state.height} Width : {this.state.width}<br />Raw Image Size: {this.state.preCompression}<br />Compressed Image Size: {this.state.postCompression}<br /><Alert color="success">{this.state.compressionSavings}</Alert></div>
            imageTypes = <div><hr /><Label>Image Type Matches</Label><br /><Button color="primary">General</Button><Button title="Image must have a height of 200px and width of 289px" color={logoColor}>Logo</Button><Button color={slideshowColor}>Slideshow</Button></div>

        }

        if (this.state.images != null) {
            search = <Input type="text" value={this.state.search} onChange={this.handleSearchChange} Placeholder="Enter 3 or more characters to search images.." />;
            images = this.state.images.map(image => {
                var imgSrc = "images/" + image.src;
                return <div id="imageContainer" onClick={() => { this.editToggle(image) }}><div id="imageContainerTop"><img style={{ maxWidth: "100%", maxHeight: "150px" }} src={imgSrc} /></div><div id="imageContainerDescription">{image.name}</div></div>;
            });
        }

        let imageField = this.state.imageBlob == null ?
            <input type="file" accept="image/*" onChange={this.handleImageUpload} /> :
            <input type="file" accept="image/*" onChange={this.handleImageUpload} disabled />;

        return (
            <div className='image-module-container'>
                <h1 className="page-title">Image Management<br />
                    <Button id="uploadImageBtn" color="success" onClick={this.toggle}>Upload Image</Button>
                </h1>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Image Upload</ModalHeader>
                    <ModalBody>
                        {imageField}
                        <br />
                        {imageStats}
                        {imagePreview}
                        {imageTypes}
                        <hr />
                        <Label>Image Description</Label>
                        <Input type="text" value={this.state.imageDescription} onChange={this.handleImageDescriptionChange} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' onClick={this.uploadImage}>Upload</Button>{' '}
                        <Button color='secondary' onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.editModal} toggle={this.editToggle} className={this.props.className}>
                    <ModalHeader toggle={this.editToggle}>Image Update</ModalHeader>
                    <ModalBody>
                        <img style={{ maxWidth: '450px' }} src={this.state.editImageUrl} />
                        <br />
                        <hr />
                        <Label>Image Description</Label>
                        <Input type="text" value={this.state.editImageDescription} onChange={this.handleEditImageDescriptionChange} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' onClick={this.editImage}>Update</Button>{' '}
                        <Button color='danger' onClick={this.deleteImage}>Delete</Button>{' '}
                        <Button color='secondary' onClick={this.editToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                {search}
                {images}
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('ImageModule/GetUserCompany', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        await currentCompanyServices.HandleMissingCompanyAsync(data);
    }
}
