import React, { Component } from 'react';
import './tagsandchips.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

/* This version of tags allows user input in a textbox form. */
export class TagsByInput extends Component {
    static displayName = TagsByInput.name;

    constructor(props) {
        super(props);
        this.state = {
            tags: [], // [{id,tag}] *id is relevant only to this component
            isRequired: this.props.isRequired != undefined ? this.props.isRequired : false,
            allowDuplicates: this.props.allowDuplicates != undefined ? this.props.allowDuplicates : false,
            inputType: this.props.inputType != undefined ? this.props.inputType : 'text', // If chosen type restricts commas, tags will break
            allowDelete: this.props.allowDelete != undefined ? this.props.allowDelete : true,
            fakeTags: this.props.fakeTags != undefined ? this.props.fakeTags : []
        };
    }

    componentDidMount() {
        let tags = this.props.tags;// This should be a comma separated string
        if (tags != undefined && tags != "" && tags != null) {
            let tagArray = tags.split(',');
            let tagPairArray = [];
            for (var i = 0; i < tagArray.length; i++) {
                tagPairArray.push({ id: i, tag: tagArray[i] });
            }
            this.setState({
                tags: tagPairArray
            });
        }
    }

    /* Send data back to parent component */
    updateParentComponent() {
        let tagOnlyArray = [];
        for (var i = 0; i < this.state.tags.length; i++) {
            tagOnlyArray.push(this.state.tags[i].tag);
        }

        let tagString = tagOnlyArray.join();
        let nameValuePair = { name: this.props.name, value: tagString };
        let fakeEvent = { target: nameValuePair };
        this.props.tagUpdate(fakeEvent);
    }

    /* Get the largest current tag id and return one larger */
    getNextTagId() {
        let currentTags = this.state.tags;
        let largestId = 0;
        for (var i = 0; i < currentTags.length; i++) {
            if (currentTags[i].id > largestId) largestId = currentTags[i].id;
        }
        return largestId + 1;
    }

    /* Convert input text to tags */
    handleChange = (e) => {
        let inputValue = e.target.value;
        let lastChar = inputValue.charAt(inputValue.length - 1);
        if (lastChar == ',') {
            let value = inputValue.slice(0, -1);
            document.getElementById('tagInput').value = "";// Reset the tag input field

            if (this.state.allowDuplicates) {
                let newTags = this.state.tags;
                newTags.push({ id: this.getNextTagId(), tag: value });

                this.setState({
                    tags: newTags
                });

                this.updateParentComponent();// Send data back to parent component
            }
            else {
                if (!this.isTagExisting(value) && !this.state.fakeTags.includes(value)) { // Check if the tag already exists
                    let newTags = this.state.tags;
                    newTags.push({ id: this.getNextTagId(), tag: value });

                    this.setState({
                        tags: newTags
                    });

                    this.updateParentComponent();// Send data back to parent component
                }
                else {
                    alert('Tag already exists');
                }
            }
        }
    }

    /* Return true if a tag is already in the array, otherwise return false. Check by tag value (not id) */
    isTagExisting(tag) {
        let currentTags = this.state.tags;
        let isExisting = false;
        for (var i = 0; i < currentTags.length; i++) {
            if (currentTags[i].tag == tag) {
                isExisting = true;
                break;
            }
        }
        return isExisting;
    }

    /* Remove tag from array */
    removeTag = (tagId) => {
        let tag;
        let currentTags = this.state.tags;
        for (var i = 0; i < currentTags.length; i++) {
            if (currentTags[i].id == tagId) {
                tag = currentTags[i].tag;
                currentTags.splice(i, 1);
            }
        }
        this.setState({ tags: currentTags });

        this.updateParentComponent();// Send data back to parent component

        // Optional removal event
        if (this.props.onTagRemoval != undefined) {
            this.props.onTagRemoval(tag);
        }
    }

    render() {
        let tagValues = this.state.tags;
        let fakeTags = this.state.fakeTags;
        let tagIcons = [];

        //Add fake tags
        for (var i = 0; i < fakeTags.length; i++) {
            tagIcons.push(<div style={{ pointerEvents: 'none' }} key={i} className='tag' title='This can not be deleted.'>{fakeTags[i]}</div>);
        }

        //Add real tags
        for (var i = 0; i < tagValues.length; i++) {
            if (this.state.allowDelete) {
                tagIcons.push(<div key={i} className='tag'>{tagValues[i].tag} <span className='remove-tag' onClick={this.removeTag.bind(this, tagValues[i].id)}><FontAwesomeIcon icon={faTimesCircle} /></span></div>);
            }
            else {
                tagIcons.push(<div style={{ pointerEvents: 'none' }} key={i} className='tag'>{tagValues[i].tag}</div>);
            }
        }

        let isRequired = (this.state.isRequired && this.state.tags.length < 1) ? true : false;
        let inputType = this.state.inputType;
        let tagInput = <input id='tagInput' name='tagInput' type={inputType} placeholder='Enter tag separated by a comma' onChange={this.handleChange} required={isRequired} />;

        return (
            <div className='tags-and-chips-container'>
                {tagInput}
                <div className='tag-wrapper'>
                    {tagIcons}
                </div>
            </div>
        );
    }
}

export default TagsByInput;
