import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Donations } from './components/Donations';
import { LocalDonation } from './components/LocalDonation';
import { LocalDonation_Edit } from './components/LocalDonation_Edit';
import { DonationFormSettings } from './components/Settings/DonationFormSettings';
import { Subscriptions } from './components/Subscriptions';
import { BackupStripe } from './components/BackupStripe';
import { MarketingEmail } from './components/MarketingEmail';
import { SendMarketingEmail } from './components/SendMarketingEmail';
import { ImageModule } from './components/ImageModule/ImageModule';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';

import './custom.css';

export default class App extends Component {
  static displayName = App.name;


    render() {
        const reload = () => window.location.reload();
    return (
      <Layout>
            <Route exact path='/' component={Home} />
            <Route path="/midtownbusinessapplication.html" onEnter={reload} />
            <Route path="/midtowninc.html" onEnter={reload} />
            <Route path="/nia.html" onEnter={reload} />
            <Route path="/wpcpca.html" onEnter={reload} />

            <Route path='/backupstripe-manual' component={BackupStripe} />

            <AuthorizeRoute path='/donations' component={Donations} />
            <AuthorizeRoute path='/localdonation' component={LocalDonation} />
            <AuthorizeRoute path='/editlocaldonation/:id' component={LocalDonation_Edit} />
            <AuthorizeRoute path='/donationformsettings' component={DonationFormSettings} />
            <AuthorizeRoute path='/subscriptions' component={Subscriptions} />
            <AuthorizeRoute path='/marketingemail' component={MarketingEmail} />
            <AuthorizeRoute path='/sendmarketingemail' component={SendMarketingEmail} />
            <AuthorizeRoute path='/imagemodule' component={ImageModule} />
      </Layout>
    );
  }
}
