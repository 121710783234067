import Cookie from 'js-cookie';

/* This class is a service class for the CurrentCompany Controller. */
export class CurrentCompanyServices {
    controller = "CurrentCompany";

    /* Reset session cookies and log user out if company is missing.
     * Return true if the company does not match the standard missing company message. */
    async HandleMissingCompanyAsync(company) {
        if (company == "missing_company") {
            await currentCompanyServices.ResetCompanyCookiesAsync();
            Cookie.remove("AuthCookie");
            window.location.replace("/");

            return false;
        }
        else {
            return true;
        }
    }

    /* Remove all company session cookies */
    async ResetCompanyCookiesAsync() {
        const response = await fetch(this.controller + '/ResetCompanyCookiesAsync', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
    }

    /* Check if there is a current company set. Return true/false */
    async HasCurrentCompanyAsync() {
        const response = await fetch(this.controller + '/HasCurrentCompany', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        return data;
    }

    /* Get the current company, if non this will redirect on the backend. */
    async GetCurrentCompanyAsync() {
        const response = await fetch(this.controller + '/GetCurrentCompany', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        return data;
    }

    /* Get the logged in company, if non this will redirect on the backend. */
    async GetLoggedInCompanyAsync() {
        const response = await fetch(this.controller + '/GetLoggedInCompany', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        return data;
    }

    /* Get the TracSoft company. */
    async GetTracSoftCompanyAsync() {
        const response = await fetch(this.controller + '/GetTracSoftCompany', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        return data;
    }

    /* Set the company associated with the TracSoft user. */
    async SetTracSoftCompanyAsync(company) {
        // Check the logged in user
        const loggedInCompanyResponse = await fetch(this.controller + '/GetLoggedInCompany', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const loggedInCompanyData = await loggedInCompanyResponse.json();

        // Set the TS company if the logged in user is TracSoft
        if (loggedInCompanyData == "TracSoft") {
            const response = await fetch(this.controller + '/SetTracSoftCompany?company=' + company, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            const data = await response.json();
        }
    }

    /* Remove the cookie for the TracSoft current company. */
    async RemoveTracSoftCompanyAsync() {
        const response = await fetch(this.controller + '/RemoveTracSoftCompany', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
    }
}

const currentCompanyServices = new CurrentCompanyServices();
export default currentCompanyServices;
