// Create/Update/Delete MarketingEmails
import React, { Component } from 'react';
import './marketingemail.css';
import currentCompanyServices from './CurrentCompanyServices';
import HtmlEditorField from './HtmlEditorField/HtmlEditorField';
import ImageGalleryField from './ImageGalleryField/ImageGalleryField';

export class MarketingEmail extends Component {
    static displayName = MarketingEmail.name;

    constructor(props) {
        super(props);
        this.state = {
            marketingEmails: [],
            newMarketingEmail: null,
            currentMarketingEmail: null,
            images: [],
            currentMarketingEmailSelection: ''
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleMarketEmailChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let currentMarketingEmailVM = this.state.currentMarketingEmail;

        let marketingEmail = currentMarketingEmailVM.marketingEmail;
        if (name == "row1ImageId" || name == "row2ImageId" || name == "row3ImageId" || name == "row4ImageId" || name == "row5ImageId" || name == "row6ImageId") {
            marketingEmail[name] = parseInt(value);
        }
        else {
            marketingEmail[name] = value;
        }
        currentMarketingEmailVM.marketingEmail = marketingEmail;

        if (name == "row1ImageId" || name == "row2ImageId" || name == "row3ImageId" || name == "row4ImageId" || name == "row5ImageId" || name == "row6ImageId") {
            let imageObjName = name == "row1ImageId" ? "row1Image" : name == "row2ImageId" ? "row2Image" : name == "row3ImageId" ? "row3Image" : name == "row4ImageId" ? "row4Image" : name == "row5ImageId" ? "row5Image" : name == "row6ImageId" ? "row6Image" : "";
            for (var i = 0; i < this.state.images.length; i++) {
                let image = this.state.images[i];
                if (image.id == value) {
                    currentMarketingEmailVM[imageObjName] = image;
                }
            }
        }

        this.setState({ currentMarketingEmail: currentMarketingEmailVM });
    }

    handleSubmit = (e) => {
        let marketingEmail = this.state.currentMarketingEmail.marketingEmail;

        let functName = marketingEmail.id == 0 ? "CreateMarketingEmail" : "UpdateMarketingEmail";
        fetch('MarketingEmail/' + functName, {
            method: 'POST',
            body: JSON.stringify(marketingEmail),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    window.location = "/marketingemail";
                }
                else {
                    alert(data);
                }
            }
        );
    }

    handleCurrentMarketingEmailSelectionChange = (e) => {
        let value = e.target.value;
        let obj = "";
        if (value == "") {
            obj = null;
        }
        else if (value == "new") {
            obj = this.state.newMarketingEmail;
        }
        else {
            for (var i = 0; i < this.state.marketingEmails.length; i++) {
                let marketingEmailVM = this.state.marketingEmails[i];
                if (marketingEmailVM.marketingEmail.id == value) {
                    obj = marketingEmailVM;
                    break;
                }
            }
        }

        this.setState({
            currentMarketingEmailSelection: e.target.value,
            currentMarketingEmail: obj
        });
    }

    handleDelete = (e) => {
        let marketingEmail = this.state.currentMarketingEmail.marketingEmail;

        let confirmDelete = window.confirm("Are you sure you want to delete " + marketingEmail.name + "?");

        if (confirmDelete) {
            fetch('MarketingEmail/DeleteMarketingEmail?marketingEmailId=' + marketingEmail.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(
                response => response.json()
            ).then(
                data => {
                    if (data == "Deleted") {
                        window.location = "/marketingemail";
                    }
                    else {
                        alert(data);
                    }
                }
            );
        }
    }

    reloadPage = (e) => {
        window.location = "/marketingemail";
    }

    handlePreview = (e) => {
        let marketingEmail = this.state.currentMarketingEmail.marketingEmail;
        let previewAddress = window.prompt("Enter email to send preview to.");

        if (previewAddress != null && previewAddress != "") {
            let emailAddresses = [];
            emailAddresses.push(previewAddress);

            let sendMarketingEmailData = {
                marketingEmail: marketingEmail,
                emailAddresses: emailAddresses
            };

            fetch('MarketingEmail/SendMarketingEmail', {
                method: 'POST',
                body: JSON.stringify(sendMarketingEmailData),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(
                response => response.json()
            ).then(
                data => {
                    alert(data);
                }
            );
        }
    }

    getRowFormated(formatVal, imgName, imgVal, txtName, txtVal) {
        var triClass = 'marketing-email__3';
        var dualClass = 'marketing-email__2';
        var singleClass = 'marketing-email__1';
        var triClassCenter = 'marketing-email__3 center-column';
        var dualClassCenter = 'marketing-email__2 center-column';
        var singleClassCenter = 'marketing-email__1 center-column';

        let rowFields = "";
        if (formatVal == "image-text_1-2") {
            rowFields = <>
                <div className={singleClassCenter}>
                    <ImageGalleryField name={imgName} currentImageUrl={imgVal} handleImageChange={this.handleMarketEmailChange} />
                </div>
                <div className={dualClass}>
                    <HtmlEditorField name={txtName} content={txtVal} handleEditorChange={this.handleMarketEmailChange} />
                </div>
            </>;
        }
        else if (formatVal == "text-image_2-1") {
            rowFields = <>
                <div className={dualClass}>
                    <HtmlEditorField name={txtName} content={txtVal} handleEditorChange={this.handleMarketEmailChange} />
                </div>
                <div className={singleClassCenter}>
                    <ImageGalleryField name={imgName} currentImageUrl={imgVal} handleImageChange={this.handleMarketEmailChange} />
                </div>
            </>;
        }
        else if (formatVal == "text_3") {
            rowFields = <>
                <div className={triClassCenter}>
                    <HtmlEditorField name={txtName} content={txtVal} handleEditorChange={this.handleMarketEmailChange} />
                </div>
            </>;
        }
        else if (formatVal == "image_3") {
            rowFields = <>
                <div className={triClass}>
                    <ImageGalleryField name={imgName} currentImageUrl={imgVal} handleImageChange={this.handleMarketEmailChange} />
                </div>
            </>;
        }
        else {
            rowFields = <>
                <div className={singleClassCenter}>
                    <ImageGalleryField name={imgName} currentImageUrl={imgVal} handleImageChange={this.handleMarketEmailChange} />
                </div>
                <div className={dualClass}>
                    <HtmlEditorField name={txtName} content={txtVal} handleEditorChange={this.handleMarketEmailChange} />
                </div>
            </>;
        }
        return rowFields;
    }

    render() {
        let marketingEmailOpts = [];
        marketingEmailOpts.push(<option key='marketingEmailSelect' value=''>--Select--</option>);
        marketingEmailOpts.push(<option key='marketingEmailNew' value='new'>New</option>);
        for (var i = 0; i < this.state.marketingEmails.length; i++) {
            let marketingEmail = this.state.marketingEmails[i].marketingEmail;

            marketingEmailOpts.push(<option key={marketingEmail.id} value={marketingEmail.id}>{marketingEmail.name}</option>);
        }

        let marketingEmailSelect = this.state.currentMarketingEmailSelection == '' ?
            <select name='marketingEmailSelection' value={this.state.currentMarketingEmailSelection} onChange={this.handleCurrentMarketingEmailSelectionChange}>
                {marketingEmailOpts}
            </select> :
            <select name='marketingEmailSelection' value={this.state.currentMarketingEmailSelection} onChange={this.handleCurrentMarketingEmailSelectionChange} disabled>
                {marketingEmailOpts}
            </select>;

        let changeSelectionBtn = this.state.currentMarketingEmailSelection == '' ?
            <button disabled>Unlock Selection</button> :
            <button type='button' onClick={this.reloadPage}>Unlock Selection</button>;

        let deleteBtn = this.state.currentMarketingEmailSelection == "" || this.state.currentMarketingEmailSelection == "new" ?
            <button disabled>Delete</button> :
            <button type='button' onClick={this.handleDelete}>Delete</button>;

        let saveBtn = this.state.currentMarketingEmailSelection == "" ?
            <button disabled>Save</button> :
            <button type='submit'>Save</button>;

        let previewBtn = this.state.currentMarketingEmailSelection == "" ?
            <button disabled>Preview</button> :
            <button type='button' onClick={this.handlePreview}>Preview</button>;

        let formBox = "";
        if (this.state.currentMarketingEmail != null) {
            let marketingEmailVM = this.state.currentMarketingEmail;
            let marketingEmail = marketingEmailVM.marketingEmail;

            let img1Src = marketingEmailVM.row1Image != null ? marketingEmailVM.row1Image.src : "";
            let img2Src = marketingEmailVM.row2Image != null ? marketingEmailVM.row2Image.src : "";
            let img3Src = marketingEmailVM.row3Image != null ? marketingEmailVM.row3Image.src : "";
            let img4Src = marketingEmailVM.row4Image != null ? marketingEmailVM.row4Image.src : "";
            let img5Src = marketingEmailVM.row5Image != null ? marketingEmailVM.row5Image.src : "";
            let img6Src = marketingEmailVM.row6Image != null ? marketingEmailVM.row6Image.src : "";

            let triClass = 'marketing-email__3';
            let dualClass = 'marketing-email__2';
            let singleClass = 'marketing-email__1';

            let inputRowClass = triClass + ' input-row';

            let formatOpts = [];
            formatOpts.push(<option value='image-text_1-2'>Image(1/3) Text(2/3)</option>);
            formatOpts.push(<option value='text-image_2-1'>Text(2/3) Image(1/3)</option>);
            formatOpts.push(<option value='text_3'>Text(3/3)</option>);
            formatOpts.push(<option value='image_3'>Image(3/3)</option>);

            let row1Fields = this.getRowFormated(marketingEmail.row1Format, "row1ImageId", img1Src, "row1Text", marketingEmail.row1Text);
            let row2Fields = this.getRowFormated(marketingEmail.row2Format, "row2ImageId", img2Src, "row2Text", marketingEmail.row2Text);
            let row3Fields = this.getRowFormated(marketingEmail.row3Format, "row3ImageId", img3Src, "row3Text", marketingEmail.row3Text);
            let row4Fields = this.getRowFormated(marketingEmail.row4Format, "row4ImageId", img4Src, "row4Text", marketingEmail.row4Text);
            let row5Fields = this.getRowFormated(marketingEmail.row5Format, "row5ImageId", img5Src, "row5Text", marketingEmail.row5Text);
            let row6Fields = this.getRowFormated(marketingEmail.row6Format, "row6ImageId", img6Src, "row6Text", marketingEmail.row6Text);

            formBox = <>
                <div className={inputRowClass}>
                    <label>Name: </label>
                    <input name='name' type='text' defaultValue={marketingEmail.name} onChange={this.handleMarketEmailChange} required />
                </div>

                <div className={inputRowClass}>
                    <label>Subject: </label>
                    <input name='subject' type='text' defaultValue={marketingEmail.subject} onChange={this.handleMarketEmailChange} required />
                </div>

                {/*
                <div className={triClass}>
                    <HtmlEditorField name='header' content={marketingEmail.header} handleEditorChange={this.handleMarketEmailChange} />
                </div>
                */}

                <div className={inputRowClass}>
                    <label>Row 1 Format: </label>
                    <select name='row1Format' value={marketingEmail.row1Format} onChange={this.handleMarketEmailChange}>
                        {formatOpts}
                    </select>
                </div>

                {row1Fields}

                <div className={inputRowClass}>
                    <label>Row 2 Format: </label>
                    <select name='row2Format' value={marketingEmail.row2Format} onChange={this.handleMarketEmailChange}>
                        {formatOpts}
                    </select>
                </div>

                {row2Fields}

                <div className={inputRowClass}>
                    <label>Row 3 Format: </label>
                    <select name='row3Format' value={marketingEmail.row3Format} onChange={this.handleMarketEmailChange}>
                        {formatOpts}
                    </select>
                </div>

                {row3Fields}

                <div className={inputRowClass}>
                    <label>Row 4 Format: </label>
                    <select name='row4Format' value={marketingEmail.row4Format} onChange={this.handleMarketEmailChange}>
                        {formatOpts}
                    </select>
                </div>

                {row4Fields}

                <div className={inputRowClass}>
                    <label>Row 5 Format: </label>
                    <select name='row5Format' value={marketingEmail.row5Format} onChange={this.handleMarketEmailChange}>
                        {formatOpts}
                    </select>
                </div>

                {row5Fields}

                <div className={inputRowClass}>
                    <label>Row 6 Format: </label>
                    <select name='row6Format' value={marketingEmail.row6Format} onChange={this.handleMarketEmailChange}>
                        {formatOpts}
                    </select>
                </div>

                {row6Fields}

                {/*
                <div className={triClass}>
                    <HtmlEditorField name='footer' content={marketingEmail.footer} handleEditorChange={this.handleMarketEmailChange} />
                </div>
                */}
            </>;
        }
        else {
            formBox = "";
        }

        return (
            <form className='marketing-email-container' onSubmit={this.handleSubmit}>
                <div className='form-title'><h3>Marketing Emails</h3></div>

                <div className='selection-row'>
                    {marketingEmailSelect}

                    {saveBtn}
                    {deleteBtn}
                    {changeSelectionBtn}
                    {previewBtn}

                    <div className='right-part'><a href='/sendmarketingemail'>Send Emails</a></div>
                </div>

                <div className='form-box'>
                    {formBox}
                </div>
            </form>
        );
    }

    async getComponentData() {
        const response = await fetch('MarketingEmail/GetAllMarketingEmailDatas', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        await currentCompanyServices.HandleMissingCompanyAsync(data.currentCompany);

        this.setState({
            marketingEmails: data.marketingEmailViewModels,
            newMarketingEmail: data.newMarketingEmail,
            images: data.images
        });
    }
}
