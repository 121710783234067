import React, {Component} from 'react';
import { compareAsc, format, parseISO, compareDesc, parse as parseDate} from 'date-fns'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Cookie from 'js-cookie';
import './donations.css';
import { GetLoggedInUserCompany, GetLoggedInUserEmail } from './Authentication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import currentCompanyServices from './CurrentCompanyServices';
import services from './Services';

export class Donations extends Component {
    static displayName = Donations.name;

    /* NOTE: There is an issue with the format of charges returned from Stripe for MemberRenewal and ProspectiveMemberApplication in comparison with the other forms.
     *       These dates are in place to help convert them to a standard format. 
     *       It is an ongoing issue so some dates might seem to be set arbitrarily but do in fact help the issue with minimizing maintenance. 
     */
    //midtownMemberDate = new Date("08-11-2020");
    midtownMemberDate = new Date("01-01-3020");

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            chooseDisplayRequired: false, 
            currentCompany: null,
            loggedInUserCompany: null,
            loggedInUserEmail: null,
            popup: null,
            rowData: [],
            limit: 25,
            net_total: 0,
            net_fees: 0,
            average_donation: 0,
            columnDefs: [
                { headerName: "First Name", valueGetter: function (params) { return params.data.firstName }, sortable: true, filter: true },
                { headerName: "Last Name", valueGetter: function (params) { return params.data.lastName }, sortable: true, filter: true },
                { headerName: "Email", valueGetter: function (params) { return params.data.email }, sortable: true, filter: true },
                //{
                //    headerName: "Charge", 
                //    valueGetter: function (params) {
                //        return "$" + (params.data.clientCharge / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                //    },
                //    sortable: true,
                //    comparator: this.priceCompare
                //},
                {
                    headerName: "Net Amount", 
                    valueGetter: (params) => {
                        if (this.notEmpty(params.data.clientNet)) {
                            if ((params.data.description == "MemberRenewal" || params.data.description == "ProspectiveMemberApplication") && (new Date(params.data.timestamp) >= this.midtownMemberDate)) {
                                if (params.data.clientNet.includes('.')) {
                                    return "$" + (parseFloat(params.data.clientNet)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                                else {
                                    return "$" + (parseInt(params.data.clientNet)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                            }
                            else {
                                return "$" + (parseInt(params.data.clientNet) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                        }
                        else {
                            return "Unavailable";
                        }
                    },
                    sortable: true,
                    comparator: this.priceCompare
                },
                //{
                //    headerName: "Fee",
                //    valueGetter:  (params) => {
                //        if (this.notEmpty(params.data.tracsoftFee) && this.notEmpty(params.data.stripeFee)) {
                //            if ((params.data.description == "MemberRenewal" || params.data.description == "ProspectiveMemberApplication") && (new Date(params.data.timestamp) >= this.midtownMemberDate)) {
                //                var stripeFee = (params.data.stripeFee.includes('.')) ? parseFloat(params.data.stripeFee) : parseInt(params.data.stripeFee);
                //                var tsFee = (params.data.tracsoftFee.includes('.')) ? parseFloat(params.data.tracsoftFee) : parseInt(params.data.tracsoftFee);
                //                return "$" + ((stripeFee + tsFee).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                //            }
                //            else {
                //                return "$" + ((parseInt(params.data.stripeFee) + parseInt(params.data.tracsoftFee)) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                //            }
                //        }
                //        else {
                //            return "Unavailable";
                //        }
                //    },
                //    sortable: true,
                //    comparator: this.priceCompare
                //},
                { headerName: "Designation", field: "description", sortable: true, filter: true },
                { headerName: "Type", valueGetter: function (params) { return params.data.transactionType }, sortable: true, filter: true },
                {
                    headerName: "Date", 
                    valueGetter: function(params){
                        return format(parseISO(params.data.timestamp),"MM-dd-yyyy");
                    }, 
                    sortable: true,
                    comparator: this.dateCompare,
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        comparator: function(filterLocalDateAtMidnight, cellValue) {
                            var dateAsString = cellValue;
        
                            if (dateAsString == null) {
                                return 0;
                            }
                            //console.log(filterLocalDateAtMidnight);
                            // In the example application, dates are stored as dd/mm/yyyy
                            // We create a Date object for comparison against the filter date
                            var dateParts = dateAsString.split('-');
                            var day = Number(dateParts[2]);
                            var month = Number(dateParts[1]) - 1;
                            var year = Number(dateParts[0]);
                            var cellDate = parseDate(cellValue, 'MM-dd-yyyy', new Date());
                            //console.log(cellDate);
                            // Now that both parameters are Date objects, we can compare
                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            } else {
                                return 0;
                            }
                        },
                        browserDatePicker: true,
                        //applyButton: true,
                        clearButton: true,
                        resetButton: true,
                        inRangeInclusive: true,
                        defaultOption:"inRange",
                        filterOptions: ["inRange"]
                    }
                },
                //{
                //    headerName: "Commemorative", 
                //    valueGetter:  (params) => {
                //        return params.data.commemorative;
                //    },
                //    sortable: true,
                //    autoHeight: true,
                //    suppressSizeToFit: true,
                //    cellClass: 'cell-wrap-text'
                //},
                //{
                //    headerName: "Comments", 
                //    autoHeight: true,
                //    valueGetter:  (params) => {
                //        if(this.notEmpty(params.data.comments)) {
                //            return params.data.comments;
                //        }
                //        else {
                //            return "";
                //        }
                //    },
                //    sortable: true,
                //    suppressSizeToFit: true,
                //    cellClass: 'cell-wrap-text'
                //},
                {
                    headerName: "",
                    cellRendererFramework: (params) => {
                        // Get popup details
                        var firstName = params.data.firstName;
                        var lastName = params.data.lastName;
                        var email = params.data.email;
                        var charge = "$" + (params.data.clientCharge / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        var fee;
                        if (this.notEmpty(params.data.tracsoftFee) && this.notEmpty(params.data.stripeFee)) {
                            if ((params.data.description == "MemberRenewal" || params.data.description == "ProspectiveMemberApplication") && (new Date(params.data.timestamp) >= this.midtownMemberDate)) {
                                var stripeFee = (params.data.stripeFee.includes('.')) ? parseFloat(params.data.stripeFee) : parseInt(params.data.stripeFee);
                                var tsFee = (params.data.tracsoftFee.includes('.')) ? parseFloat(params.data.tracsoftFee) : parseInt(params.data.tracsoftFee);
                                fee = "$" + ((stripeFee + tsFee).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                            else {
                                fee = "$" + ((parseInt(params.data.stripeFee) + parseInt(params.data.tracsoftFee)) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                        }
                        else {
                            fee = "Unavailable";
                        }
                        var netAmount;
                        if (this.notEmpty(params.data.clientNet)) {
                            if ((params.data.description == "MemberRenewal" || params.data.description == "ProspectiveMemberApplication") && (new Date(params.data.timestamp) >= this.midtownMemberDate)) {
                                if (params.data.clientNet.includes('.')) {
                                    netAmount = "$" + (parseFloat(params.data.clientNet)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                                else {
                                    netAmount = "$" + (parseInt(params.data.clientNet)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                            }
                            else {
                                netAmount = "$" + (parseInt(params.data.clientNet) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                        }
                        else {
                            netAmount = "Unavailable";
                        }
                        var designation = params.data.description;
                        var transactionType = params.data.transactionType;
                        var timestamp = format(parseISO(params.data.timestamp), "MM-dd-yyyy");
                        var commemorative = params.data.commemorative;
                        var comments = params.data.comments;
                        var localType = params.data.localType;
                        var reference = params.data.reference;

                        var popupId = "popup" + params.data.tempId;

                        // Optional Popup fields
                        var localTypeField = (localType != null && localType != "" && localType != undefined) ?
                            <>
                                <label>Local Type: </label><p>{localType}</p>
                            </> : "";
                        var referenceField = (reference != null && reference != "" && reference != undefined) ?
                            <>
                                <label>Reference: </label><p>{reference}</p>
                            </> : "";

                        // Create popup
                        var popup = <div id={popupId} className='popup-wrapper'>
                            <div className='popup-container'>
                                <div className='popup-header'>
                                    <div className='popup-header-management'>
                                        <FontAwesomeIcon className='popup-close-btn' icon={faTimesCircle} onClick={this.handlePopupDisplay.bind(this, null)} />
                                    </div>
                                </div>
                                <div className='popup-contents'>
                                    <label>First Name: </label><p>{firstName}</p>
                                    <label>Last Name: </label><p>{lastName}</p>
                                    <label>Email: </label><p>{email}</p>
                                    <label>Charge: </label><p>{charge}</p>
                                    <label>Fee: </label><p>{fee}</p>
                                    <label>Net: </label><p>{netAmount}</p>
                                    <label>Designation: </label><p>{designation}</p>
                                    <label>Type: </label><p>{transactionType}</p>
                                    <label>Timestamp: </label><p>{timestamp}</p>
                                    <label>Commemorative: </label><p>{commemorative}</p>
                                    <label>Comments: </label><p>{comments}</p>
                                    {localTypeField}
                                    {referenceField}
                                </div>
                            </div>
                        </div>;

                        var detailsBtn = <>
                            <p className='details-btn-link' onClick={this.handlePopupDisplay.bind(this, popup)}>Details</p>
                        </>;

                        var manageButtons;
                        if (params.data.transactionType == "Offline") {
                            var deleteBtn = <p className='delete-btn-link' onClick={this.deleteLocalDonation.bind(this, params.data.componentId)}>Void</p>;
                            var editBtn = <a href={'/editlocaldonation/' + params.data.componentId } className='edit-btn-link'>Edit</a>;

                            manageButtons = <div className='manage-btn-container'>{detailsBtn} {editBtn} {deleteBtn}</div>;
                        }
                        else {
                            manageButtons = <div className='manage-btn-container'>{detailsBtn}</div>;
                        }

                        return manageButtons;
                    },
                    sortable: false,
                    filter: false
                }
            ]
        };
    }

    handlePopupDisplay = (popupContent) => {
        this.setState({ popup: popupContent });
    }

    onColumnResized(params) {
        params.api.resetRowHeights();
    }

    notEmpty(n) {
        let notEmpty = true;

        if(n == "")  {
            notEmpty = false;
        }
        if(n == null) {
            notEmpty = false;
        }
        if(n == "NaN") {
            notEmpty = false;
        }
        return notEmpty;
    }

    dateCompare(a,b) {
        a = parseDate(a, 'MM-dd-yyyy', new Date());
        b = parseDate(b, 'MM-dd-yyyy', new Date());
        return compareDesc(a,b);
    }

    priceCompare(a, b) {
        a = parseInt(parseFloat(a.replace("$","")) * 100);
        b = parseInt(parseFloat(b.replace("$","")) * 100);
  
        if (a === null && b === null || (a === "NaN" && b == "NaN")) {
            return 0;
        }
        if (a === null || a === "NaN") {
            return -1;
        }
        if (b === null || b === "NaN") {
            return 1;
        }
  
        return a - b;
    }

    componentDidMount() {
        this.determineDisplay();
    }

    /* Determine the original display depending on the user's company */
    determineDisplay = async () => {
        let companyCookie = await currentCompanyServices.GetLoggedInCompanyAsync();
        if (companyCookie == null || companyCookie == "" || companyCookie == undefined) {
            await currentCompanyServices.HandleMissingCompanyAsync("missing_company");
        }

        let loggedInUserCompany = await GetLoggedInUserCompany();
        let loggedInUserEmail = await GetLoggedInUserEmail();
        
        if (loggedInUserCompany == "TracSoft") {
            let tsCompany = await currentCompanyServices.GetTracSoftCompanyAsync();
            if (tsCompany != null && tsCompany != "") {
                this.setState({
                    loggedInUserCompany: loggedInUserCompany,
                    loggedInUserEmail: loggedInUserEmail,
                    currentCompany: tsCompany
                });
                this.populateTransactionData(tsCompany);
            }
            else {
                this.setState({
                    loading: false,
                    chooseDisplayRequired: true,
                    loggedInUserCompany: loggedInUserCompany,
                    loggedInUserEmail: loggedInUserEmail
                });
            }
        }
        else {
            this.setState({
                loggedInUserCompany: loggedInUserCompany,
                loggedInUserEmail: loggedInUserEmail
            });
            this.populateTransactionData(null);
        }
    }

    /* Reload the page */
    async reloadPage() {
        let loggedInCompany = await currentCompanyServices.GetLoggedInCompanyAsync();
        if (loggedInCompany == "TracSoft") {
            await currentCompanyServices.RemoveTracSoftCompanyAsync(); // Remove current company cookie
        }
        
        window.location.href = window.location.href;
    }

    populateTransactionData = async (companyName) => {
        let token = Cookie.get("AuthCookie");
        const response = await fetch("transactions/getall", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token,
                limit: this.state.limit,
                date_options: {
                    gte: "01-01-2020",
                    lte: this.state.dfLTE
                },
                companyName: companyName
            })
        }).then(r => r.json()).then(charges => {
            //console.log(d);
            //if (charges.length > 0) {
                let net_total = 0;
                let net_fees = 0;
                let average_donation = 0;
                charges.map((charge) => {
                    if (this.notEmpty(charge.clientNet)) {
                        if ((charge.description == "MemberRenewal" || charge.description == "ProspectiveMemberApplication") && (new Date(charge.timestamp) >= this.midtownMemberDate)) {
                            if (charge.clientNet.includes('.')) {
                                net_total += parseInt(parseFloat(charge.clientNet).toFixed(2).toString().replace('.', ''));
                            }
                            else {
                                net_total += parseInt(charge.clientNet + "00");
                            }
                        }
                        else {
                            net_total += parseInt(charge.clientNet);
                        }
                    }
                    if (this.notEmpty(charge.stripeFee) && this.notEmpty(charge.tracsoftFee)) {
                        if ((charge.description == "MemberRenewal" || charge.description == "ProspectiveMemberApplication") && (new Date(charge.timestamp) >= this.midtownMemberDate)) {
                            var stripeFee = (charge.stripeFee.includes('.')) ? parseFloat(charge.stripeFee).toFixed(2).toString().replace('.', '') : charge.stripeFee + "00";
                            var tsFee = (charge.tracsoftFee.includes('.')) ? parseFloat(charge.tracsoftFee).toFixed(2).toString().replace('.', '') : charge.tracsoftFee + "00";

                            net_fees += (parseInt(stripeFee) + parseInt(tsFee));
                        }
                        else {
                            net_fees += (parseInt(charge.stripeFee) + parseInt(charge.tracsoftFee));
                        }
                    }
                });
                if (net_total != 0) {
                    average_donation = parseInt(Math.round(net_total / charges.length));
                }
                this.setState({
                    net_total: net_total,
                    net_fees: net_fees,
                    average_donation,
                    rowData: charges,
                    loading: false,
                    chooseDisplayRequired: false
                });
            //} else {
            //    this.setState({
            //        loading: false,
            //        rowData: [],
            //        limit: this.state.limit
            //    });
            //}
        });
    }

    /* Populate the table once the TS admin has selected a client */
    populateClientTransactionData = async (clientCompany) => {
        let loggedInUserCompany = await GetLoggedInUserCompany();
        if (loggedInUserCompany == "TracSoft") {
            await currentCompanyServices.SetTracSoftCompanyAsync(clientCompany); // Set cookies for current company

            window.location.href = window.location.href;
        }
    }

    openDonationForm(link) {
        window.open(link);
    }

    /* Do stuff external to ag-grid when the filter changes */
    onFilterChanged = () => {
        this.updateTotals();
    }

    /* Update the total values at the top to reflect the filters */
    updateTotals = () => {
        var model = this.gridApi.getModel();
        if (model != null && model != undefined) {
            var rows = model.rowsToDisplay;

            let net_total = 0;
            let net_fees = 0;
            let average_donation = 0;
            if (rows.length > 0) {
                //console.log(rows);
                rows.map((row) => {
                    if (this.notEmpty(row.data.clientNet)) {
                        if ((row.data.description == "MemberRenewal" || row.data.description == "ProspectiveMemberApplication") && (new Date(row.data.timestamp) >= this.midtownMemberDate)) {
                            if (row.data.clientNet.includes('.')) {
                                net_total += parseInt(parseFloat(row.data.clientNet).toFixed(2).toString().replace('.', ''));
                            }
                            else {
                                net_total += parseInt(row.data.clientNet + "00");
                            }
                        }
                        else {
                            net_total += parseInt(row.data.clientNet);
                        }
                    }
                    if (this.notEmpty(row.data.stripeFee) && this.notEmpty(row.data.tracsoftFee)) {
                        if ((row.data.description == "MemberRenewal" || row.data.description == "ProspectiveMemberApplication") && (new Date(row.data.timestamp) >= this.midtownMemberDate)) {
                            var stripeFee = (row.data.stripeFee.includes('.')) ? parseFloat(row.data.stripeFee).toFixed(2).toString().replace('.', '') : row.data.stripeFee + "00";
                            var tsFee = (row.data.tracsoftFee.includes('.')) ? parseFloat(row.data.tracsoftFee).toFixed(2).toString().replace('.', '') : row.data.tracsoftFee + "00";

                            net_fees += (parseInt(stripeFee) + parseInt(tsFee));
                        }
                        else {
                            net_fees += (parseInt(row.data.stripeFee) + parseInt(row.data.tracsoftFee));
                        }
                    }
                });
                if (net_total != 0) {
                    average_donation = parseInt(Math.round(net_total / rows.length));
                }

                this.setState({
                    net_total: net_total,
                    net_fees: net_fees,
                    average_donation: average_donation
                });
            }
            else {
                this.setState({
                    net_total: 0,
                    net_fees: 0,
                    average_donation: 0
                });
            }
        }
    }

    /* Reset all of the filters with a single click */
    resetAllFilters = () => {
        this.gridApi.setFilterModel(null);
    }

    /* Save the current table as a csv file */
    onBtnExportDataAsCsv = () => {
        var rows = this.state.rowData;
        var params = {
            rows,
            fileName: "Donations"
        };

        this.gridApi.exportDataAsCsv(params);
    };

    /* Create and prompt print of report by email */
    createReport = () => {
        let model = this.gridApi.getModel();
        if (model != null && model != undefined) {
            let rawRows = model.rowsToDisplay;

            // Get data from rows
            let rows = [];
            rawRows.map((row) => {
                rows.push(row.data);
            });

            // Order by last name
            rows.sort(function (a, b) {
                let lastNameA = a.lastName != null ? a.lastName.toLowerCase() : "";
                let lastNameB = b.lastName != null ? b.lastName.toLowerCase() : "";
                if (lastNameA < lastNameB) {
                    return -1;
                }
                if (lastNameA > lastNameB) {
                    return 1;
                }

                return 0;
            });
            //console.log(rows);

            // Group by email
            let reportContentsObj = rows.reduce(function (r, a) {
                let email = a.email != null ? a.email.toLowerCase() : "";
                r[email] = r[email] || [];
                r[email].push(a);
                    return r;
                }, Object.create(null));
            //console.log(reportContentsObj);

            // Convert object to array
            let reportContents = [];
            for (var prop in reportContentsObj) {
                if (Object.prototype.hasOwnProperty.call(reportContentsObj, prop)) {
                    //Add start trigger
                    reportContents.push("#S##");

                    let propEmailKey = reportContentsObj[prop];
                    for (var propKey in propEmailKey) {
                        reportContents.push(propEmailKey[propKey]);
                    }

                    //Add page break (end) trigger
                    reportContents.push("#E##");
                }
            }
            //console.log(reportContents);

            // Create layout and print
            var mywindow = window.open('', 'PRINT');
            mywindow.document.write('<html><body>');

            let total = 0;
            let currentDate = services.formatDateTime(new Date(), false, true, false, false);
            reportContents.map((content) => {
                if (content == "#S##") {

                    mywindow.document.write('<h2 style="display: inline-flex;">' + this.state.currentCompany + ' Donation Report</h2><h3 style="float: right;">Report Date: ' + currentDate + '</h3></br>');
                    mywindow.document.write('<table style="width: 100%; border-collapse: collapse; border: 1px solid black;">');
                    mywindow.document.write('<tr>');
                    mywindow.document.write('<th style="border: 1px solid black;">NAME</th>');
                    mywindow.document.write('<th style="border: 1px solid black;">EMAIL</th>');
                    mywindow.document.write('<th style="border: 1px solid black;">DESIGNATION</th>');
                    mywindow.document.write('<th style="border: 1px solid black;">REFERENCE</th>');
                    mywindow.document.write('<th style="border: 1px solid black;">AMOUNT</th>');
                    mywindow.document.write('<th style="border: 1px solid black;">DATE</th>');
                    mywindow.document.write('</tr>');
                }
                else if (content == "#E##") {
                    mywindow.document.write('</table>');

                    var totalFormat = "$" + (total / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    mywindow.document.write('</br><h3 style="page-break-after: always;">Total: ' + totalFormat + '</h3>');
                    total = 0;
                }
                else {
                    mywindow.document.write('<tr>');
                    mywindow.document.write('<td style="border: 1px solid black;">' + content.lastName + ", " + content.firstName + '</td>');
                    mywindow.document.write('<td style="border: 1px solid black;">' + content.email + '</td>');
                    mywindow.document.write('<td style="border: 1px solid black;">' + content.description + '</td>');

                    var reference = content.transactionType == "Offline" && content.reference != null && content.reference != "" ? content.reference : content.transactionType;
                    mywindow.document.write('<td style="border: 1px solid black;">' + reference + '</td>');

                    var charge = "$" + (content.clientCharge / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    mywindow.document.write('<td style="border: 1px solid black;">' + charge + '</td>');
                    total += content.clientCharge;

                    mywindow.document.write('<td style="border: 1px solid black;">' + format(parseISO(content.timestamp), "MMM dd, yyyy") + '</td>');
                    mywindow.document.write('</tr>');
                }
            });

            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            return true;
        }
    }

    /* Create and prompt print of report by last,first name */
    createNameReport = () => {
        let model = this.gridApi.getModel();
        if (model != null && model != undefined) {
            let rawRows = model.rowsToDisplay;

            // Get data from rows
            let rows = [];
            rawRows.map((row) => {
                rows.push(row.data);
            });

            // Order by last name
            rows.sort(function (a, b) {
                let lastNameA = a.lastName != null ? a.lastName.toLowerCase() : "";
                let lastNameB = b.lastName != null ? b.lastName.toLowerCase() : "";
                if (lastNameA < lastNameB) {
                    return -1;
                }
                if (lastNameA > lastNameB) {
                    return 1;
                }

                return 0;
            });
            //console.log(rows);

            // Group by last, first name
            let reportContentsObj = rows.reduce(function (r, a) {
                let lastName = a.lastName != null ? a.lastName.toLowerCase() : "";
                let firstName = a.firstName != null ? a.firstName.toLowerCase() : "";
                let lastFirst = lastName + ", " + firstName;
                r[lastFirst] = r[lastFirst] || [];
                r[lastFirst].push(a);

                return r;
            }, Object.create(null));
            //console.log(reportContentsObj);

            // Convert object to array
            let reportContents = [];
            for (var prop in reportContentsObj) {
                if (Object.prototype.hasOwnProperty.call(reportContentsObj, prop)) {
                    //Add start trigger
                    reportContents.push("#S##");

                    let propEmailKey = reportContentsObj[prop];
                    for (var propKey in propEmailKey) {
                        reportContents.push(propEmailKey[propKey]);
                    }

                    //Add page break (end) trigger
                    reportContents.push("#E##");
                }
            }
            //console.log(reportContents);

            // Create layout and print
            var mywindow = window.open('', 'PRINT');
            mywindow.document.write('<html><body>');

            let total = 0;
            let currentDate = services.formatDateTime(new Date(), false, true, false, false);
            reportContents.map((content) => {
                if (content == "#S##") {

                    mywindow.document.write('<h2 style="display: inline-flex;">' + this.state.currentCompany + ' Donation Report</h2><h3 style="float: right;">Report Date: ' + currentDate + '</h3></br>');
                    mywindow.document.write('<table style="width: 100%; border-collapse: collapse; border: 1px solid black;">');
                    mywindow.document.write('<tr>');
                    mywindow.document.write('<th style="border: 1px solid black;">NAME</th>');
                    mywindow.document.write('<th style="border: 1px solid black;">EMAIL</th>');
                    mywindow.document.write('<th style="border: 1px solid black;">DESIGNATION</th>');
                    mywindow.document.write('<th style="border: 1px solid black;">REFERENCE</th>');
                    mywindow.document.write('<th style="border: 1px solid black;">AMOUNT</th>');
                    mywindow.document.write('<th style="border: 1px solid black;">DATE</th>');
                    mywindow.document.write('</tr>');
                }
                else if (content == "#E##") {
                    mywindow.document.write('</table>');

                    var totalFormat = "$" + (total / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    mywindow.document.write('</br><h3 style="page-break-after: always;">Total: ' + totalFormat + '</h3>');
                    total = 0;
                }
                else {
                    mywindow.document.write('<tr>');
                    mywindow.document.write('<td style="border: 1px solid black;">' + content.lastName + ", " + content.firstName + '</td>');
                    mywindow.document.write('<td style="border: 1px solid black;">' + content.email + '</td>');
                    mywindow.document.write('<td style="border: 1px solid black;">' + content.description + '</td>');

                    var reference = content.transactionType == "Offline" && content.reference != null && content.reference != "" ? content.reference : content.transactionType;
                    mywindow.document.write('<td style="border: 1px solid black;">' + reference + '</td>');

                    var charge = "$" + (content.clientCharge / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    mywindow.document.write('<td style="border: 1px solid black;">' + charge + '</td>');
                    total += content.clientCharge;

                    mywindow.document.write('<td style="border: 1px solid black;">' + format(parseISO(content.timestamp), "MMM dd, yyyy") + '</td>');
                    mywindow.document.write('</tr>');
                }
            });

            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            return true;
        }
    }

    saveAPI = (params) => { //Assigns AG Grid APIs to properties for further calls.
        this.api = params.api;
        this.columnApi = params.columnApi;
        this.api.sizeColumnsToFit();
        this.gridApi = params.api;
    }

    deleteLocalDonation = async (componentId) => {
        let confirmDelete = window.confirm("Are you sure you want to void this transaction?");

        if (confirmDelete) {
            let userEmail = this.state.loggedInUserEmail != null ? this.state.loggedInUserEmail : "";
            const response = await fetch('LocalTransaction/DeleteLocalTransaction?componentId=' + componentId + '&userEmail=' + userEmail, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            const data = await response.json();

            window.location.href = window.location.href;
        }
    }

    render() {
        let tableHeader;
        if (this.state.currentCompany != null) {
            if (this.state.loggedInUserCompany == "TracSoft") {
                tableHeader = <div className='table-header'>
                    <FontAwesomeIcon className='back-btn' icon={faArrowAltCircleLeft} onClick={this.reloadPage} />
                    <span className='header-text'>{this.state.currentCompany}</span>
                </div>;
            }
            else {
                
            }
        }
        
        if (this.state.loading) {
            return (<p><em>Loading...</em></p>);
        }
        else if (this.state.chooseDisplayRequired) {
            return (
                <div className="ag-theme-balham donations-container" style={{ height: "100%", width: "100%" }}>
                    <div className="client-selection-container">
                        <span className='grid-header'>Payments</span>
                        <span className='grid__2-4 grid-header'>Forms</span>

                        <button onClick={this.populateClientTransactionData.bind(this, "TSDemo")}>TSDemo</button>
                        <button onClick={this.openDonationForm.bind(this, "/demo.html")} className='grid__2-4'>Donate</button>

                        <button onClick={this.populateClientTransactionData.bind(this, "MidtownInc")}>Midtown</button>
                        <button onClick={this.openDonationForm.bind(this, "/midtowninc.html")}>Donate</button>
                        <button onClick={this.openDonationForm.bind(this, "/midtownbusinessrenewal.html")}>Member Renewal</button>
                        <button onClick={this.openDonationForm.bind(this, "/midtownbusinessapplication.html")}>Member Application</button>

                        <button onClick={this.populateClientTransactionData.bind(this, "NIA")}>NIA</button>
                        <button onClick={this.openDonationForm.bind(this, "/nia.html")} className='grid__2-4'>Donate</button>

                        <button onClick={this.populateClientTransactionData.bind(this, "JeeahsHope")}>JeeahsHope</button>
                        <button onClick={this.openDonationForm.bind(this, "/jeeahshope.html")} className='grid__2-4'>Donate</button>


                        <button onClick={this.populateClientTransactionData.bind(this, "Westminster")}>Westminster</button>
                        <button onClick={this.openDonationForm.bind(this, "/wpcpca.html")} className='grid__2-4'>Donate</button>

                        <button onClick={this.populateClientTransactionData.bind(this, "EnrichmentServices")}>Enrichment Services</button>
                        <button onClick={this.openDonationForm.bind(this, "/enrichmentservices.html")} className='grid__2-4'>Donate</button>

                        <button onClick={this.populateClientTransactionData.bind(this, "BicycleColumbus")}>Bicycle Columbus</button>
                        <button onClick={this.openDonationForm.bind(this, "/bicyclecolumbus.html")} className='grid__2-4'>Donate</button>

                        <button onClick={this.populateClientTransactionData.bind(this, "StLuke")}>St. Luke</button>
                        <button onClick={this.openDonationForm.bind(this, "/stluketithe.html")}>Tithe</button>
                        <button onClick={this.openDonationForm.bind(this, "/stluketuition.html")}>Tuition</button>
                        <div></div>

                        <button onClick={this.populateClientTransactionData.bind(this, "TurnAroundColumbus")}>TurnAroundColumbus</button>
                        <button onClick={this.openDonationForm.bind(this, "/turnaroundcolumbus.html")} className='grid__2-4'>Donate</button>
                    </div>
                </div>  
            );
        }
        else {
            return (
                <div className="ag-theme-balham donations-container" style={{ height: "100%", width: "100%" }}>
                    {tableHeader}

                    {this.state.popup}

                    <div>
                        <div style={{float: "left"}}>
                            <div>
                                {/*
                                <label style={{ marginRight: 5 }}>Average Donation: </label>
                                <span>$</span><span style={{ marginRight: 15 }}>{(this.state.average_donation / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                */}
                                
                                {/*
                                <label style={{ marginRight: 5 }}>Net Fees: </label>
                                <span>-$</span><span>{(this.state.net_fees / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                */}

                                <button style={{ marginBottom: '3px' }} className='btn btn-secondary' onClick={() => this.resetAllFilters()}>Reset Filters</button>
                                <button style={{ marginLeft: '10px', marginBottom: '3px' }} className='btn btn-secondary' onClick={() => this.createReport()}>Report (email)</button>
                                <button style={{ marginLeft: '10px', marginBottom: '3px' }} className='btn btn-secondary' onClick={() => this.createNameReport()}>Report (name)</button>
                                <button style={{ marginLeft: '10px', marginBottom: '3px' }} className='btn btn-secondary' onClick={() => this.onBtnExportDataAsCsv()}>Download</button>

                                <label style={{ marginLeft: '10px', marginRight: 5 }}>Net Total: </label>
                                <span>$</span><span style={{ marginRight: 15 }}>{(this.state.net_total / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            </div>
                        </div>

                        <div style={{ float: 'right', paddingTop: '1%' }}>
                            <label style={{ marginRight: 15 }}>Limit: </label>
                            <select id="limitDdl" style={{ display: "inline-block" }} value={this.state.limit} onChange={(event) => { this.setState({ limit: parseInt(event.target.value) }, () => { this.api.paginationSetPageSize(this.state.limit); }); }}>
                                <option key="5" value="5">5</option>
                                <option key="10" value="10">10</option>
                                <option key="25" value="25">25</option>
                                <option key="50" value="50">50</option>
                                <option key="100" value="100">100</option>
                            </select>
                        </div>
                    </div>
                    
                    <div style={{ clear: "both" }}></div>

                    <AgGridReact
                        onGridReady={this.saveAPI}
                        pagination="true"
                        paginationPageSize="25"
                        rowDataChangeDetectionStrategy='IdentityCheck'
                        domLayout='autoHeight'
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        onColumnResized={this.onColumnResized}
                        onFilterChanged={this.onFilterChanged}
                    >
                    </AgGridReact>
                </div>
            );
        }
    }
}
