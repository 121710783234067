import Cookie from "js-cookie"

export class AuthorizeService {
    _isAuthenticated = false;
    _token = null;

    async isValidToken() {
        this.token = Cookie.get("AuthCookie");
        const response = await fetch('Authentication/Get', {
            method: 'GET',
            headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + this.token
            }
        });
        const responseCode = await response.status;
        if (responseCode === 200) {
            
            return true;
        }
        return false;
    }

    async getLoggedInUserRole() {
        let cookie = Cookie.get("AuthCookie");
        const response = await fetch('Authentication/GetLoggedInUserRole?cookie=' + cookie, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
        let responseRole = await response.text();
        if (responseRole.charAt(0) === "\"" && responseRole.charAt(responseRole.length - 1) === "\"") responseRole = responseRole.slice(1, -1);
        return responseRole;
    }

    async getLoggedInUserEmployeeNumber() {
        let cookie = Cookie.get("AuthCookie");
        const response = await fetch('Authentication/GetLoggedInUserEmployeeNumber?cookie=' + cookie, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
        let responseNumb = await response.text();
        if (responseNumb.charAt(0) === "\"" && responseNumb.charAt(responseNumb.length - 1) === "\"") responseNumb = responseNumb.slice(1, -1);
        return responseNumb;
    }

    async logOff() {
        Cookie.remove("AuthCookie");
        return true;
    }

    static get instance() { return authService }
}

const authService = new AuthorizeService();

export default authService;