import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'
import currentCompanyServices from '../CurrentCompanyServices';

export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false,
            token: null,
            loggedInCompany: null,
            currentCompany: null
        };
    }

    componentDidMount() {
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated } = this.state;
        const redirectUrl = '/';
        if (!ready) {
            return <div></div>;
        } else {
            const { component: Component, ...rest } = this.props;
            return <Route {...rest}
                render={(props) => {
                    if (authenticated) {
                        let path = props.match.path;
                        let hasLoggedInCompany = (this.state.loggedInCompany == null || this.state.loggedInCompany == "" || this.state.loggedInCompany == undefined || this.state.loggedInCompany == "missing_company") ? false : true;
                        let hasCurrentCompany = (this.state.currentCompany == null || this.state.currentCompany == "" || this.state.currentCompany == undefined || this.state.currentCompany == "missing_company") ? false : true;

                        if (path == "/backupstripe") {
                            return <Component {...props} />
                        }
                        else if (!hasLoggedInCompany) {
                            return <Redirect to={redirectUrl} />
                        }
                        else if ((this.state.loggedInCompany == "TracSoft" && path != "/donations" && !hasCurrentCompany) || (this.state.loggedInCompany != "TracSoft" && !hasCurrentCompany)) {
                            return <Redirect to={redirectUrl} />
                        }
                        else {
                            return <Component {...props} />
                        }
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isValidToken();
        let loggedInCompany = await currentCompanyServices.GetLoggedInCompanyAsync();
        let currentCompany = await currentCompanyServices.GetCurrentCompanyAsync();

        this.setState({
            ready: true,
            authenticated,
            loggedInCompany: loggedInCompany,
            currentCompany: currentCompany
        });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}