import React, { Component } from 'react';
import './sendmarketingemail.css';
import currentCompanyServices from './CurrentCompanyServices';

export class SendMarketingEmail extends Component {
    static displayName = SendMarketingEmail.name;

    constructor(props) {
        super(props);
        this.state = {
            marketingEmails: [],
            emailFilter: null,
            selectedEmail: null,
            emailAddresses: []
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleEmailFilterChange = (e) => {
        let emailFilter = this.state.emailFilter;

        if (e.target.name == "marketingEmailId") {
            emailFilter[e.target.name] = parseInt(e.target.value);
        }
        else {
            emailFilter[e.target.name] = e.target.value;
        }
        
        this.setState({ emailFilter: emailFilter });
    }

    getFilteredEmails = (e) => {
        let emailFilter = this.state.emailFilter;

        fetch('MarketingEmail/GetFilteredEmails', {
            method: 'POST',
            body: JSON.stringify(emailFilter),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                console.log(data);
                this.setState({
                    selectedEmail: data.marketingEmail,
                    emailAddresses: data.emailAddresses
                });
            }
        );
    }

    sendEmails = (e) => {
        let confirmSend = window.confirm(this.state.emailAddresses.length + " emails were found. Would you like to send the email?");
        if (confirmSend) {
            let sendMarketingEmailData = {
                marketingEmail: this.state.selectedEmail,
                emailAddresses: this.state.emailAddresses
            };

            fetch('MarketingEmail/SendMarketingEmail', {
                method: 'POST',
                body: JSON.stringify(sendMarketingEmailData),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(
                sendResponse => sendResponse.json()
            ).then(
                sendData => {
                    alert(sendData);
                }
            );
        }
    }

    render() {
        let marketingEmailOpts = [];
        marketingEmailOpts.push(<option key='marketingEmailSelect' value=''>--Select--</option>);
        for (var i = 0; i < this.state.marketingEmails.length; i++) {
            let marketingEmail = this.state.marketingEmails[i];

            marketingEmailOpts.push(<option key={marketingEmail.id} value={marketingEmail.id}>{marketingEmail.name}</option>);
        }

        let selectEmailField;
        if (this.state.emailFilter != null) {
            selectEmailField = <select name='marketingEmailId' value={this.state.emailFilter.marketingEmailId} onChange={this.handleEmailFilterChange} required>
                {marketingEmailOpts}
            </select>;
        }

        let sendEmailBtn = this.state.emailFilter != null && this.state.emailFilter.marketingEmailId != null && this.state.emailFilter.marketingEmailId != '' && this.state.emailFilter.marketingEmailId != 0 && this.state.selectedEmail != null && this.state.emailAddresses.length > 0 ?
            <button type='button' onClick={this.sendEmails}>Send Emails</button> :
            <button type='button' disabled>Send Emails</button>;

        return (
            <div className='send-marketing-email-container'>
                <div className='form-title'><h3>Send Marketing Emails</h3></div>

                <form >
                    <div className='filter-row'>
                        <label>Email: </label>
                        {selectEmailField}
                    </div>

                    <div className='task-row'>
                        <button type='button' onClick={this.getFilteredEmails}>Get Emails</button>
                        {sendEmailBtn}
                    </div>
                </form>
            </div>  
        );
    }

    async getComponentData() {
        const response = await fetch('MarketingEmail/GetMarketingEmailSendData', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        await currentCompanyServices.HandleMissingCompanyAsync(data.currentCompany);

        this.setState({
            marketingEmails: data.marketingEmails,
            emailFilter: data.marketingEmailFilter
        });
    }
}

