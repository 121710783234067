import React, { Component } from 'react';

export class BackupStripe extends Component {
    static displayName = BackupStripe.name;

    constructor(props) {
        super(props);
        this.state = {
            isComplete: false
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let backupStatus = this.state.isComplete ? "Backup Completed" : "Loading";

        return (
            <div>{backupStatus}</div>
        );
    }

    async getComponentData() {
        const response = await fetch('Script/BackupStripeTransactions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        this.setState({
            isComplete: true
        });
    }
}
