import React, { Component } from 'react';
import '../forms.css';
import { TagsByInput } from '../TagsAndChips/TagsByInput';
import currentCompanyServices from '../CurrentCompanyServices';

export class DonationFormSettings extends Component {
    static displayName = DonationFormSettings.name;

    constructor(props) {
        super(props);
        this.state = {
            donationFormSettings: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        let newDonationFormSettings = this.state.donationFormSettings;

        // Update current field
        newDonationFormSettings[name] = value;

        // Update related fields
        if (name == "designationDefault") {
            newDonationFormSettings["designationDistinctDefault"] = null;
        }

        this.setState({ donationFormSettings: newDonationFormSettings });
    }

    async onTypeTagRemoval(tag) {
        //let removedTags = this.state.removedTags;
        //removedTags.push(tag);
        //this.setState({ removedTags: removedTags });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let donationFormSettings = this.state.donationFormSettings;

        fetch('Settings/ManageDonationFormSettings', {
            method: 'POST',
            body: JSON.stringify(donationFormSettings),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    window.location = "/donations";
                }
                else {
                    currentCompanyServices.HandleMissingCompanyAsync(data);
                    alert(data);
                }
            }
        );
    }

    render() {
        let designationOrder;
        let designationDefault;
        let designationDistinctDefault;

        if (this.state.donationFormSettings != null) {
            let donationFormSettings = this.state.donationFormSettings;
            designationOrder = donationFormSettings.designationOrder;
            designationDefault = donationFormSettings.designationDefault;
            designationDistinctDefault = donationFormSettings.designationDistinctDefault;
        }

        let fakeTags = ["Donation"];
        let designationInput = this.state.donationFormSettings != null ?
            <TagsByInput
                tags={this.state.donationFormSettings.designations}
                tagUpdate={this.handleChange}
                name='designations'
                isRequired={false}
                allowDuplicates={false}
                inputType='text'
                onTagRemoval={this.onTypeTagRemoval}
                fakeTags={fakeTags}
            /> : "";

        let designationDistinctDefaultField;
        if (designationDefault == "distinct") {
            let distinctOptStrings = this.state.donationFormSettings.designations != null && this.state.donationFormSettings.designations.length > 0 ? this.state.donationFormSettings.designations.split(",") : [];
            distinctOptStrings.unshift(fakeTags);

            let distinctOpts = [];
            for (var i = 0; i < distinctOptStrings.length; i++) {
                let optKey = "designationDistinctDefault" + i;
                distinctOpts.push(<option key={optKey} value={distinctOptStrings[i]}>{distinctOptStrings[i]}</option>);
            }

            designationDistinctDefaultField = <>
                <label>Distinct Default: </label>
                <select name='designationDistinctDefault' value={designationDistinctDefault} onChange={this.handleChange} required>
                    <option key='designationDistinctDefaultSelect' value=''>--Select--</option>
                    {distinctOpts}
                </select>
            </>;
        }

        return (
            <form onSubmit={this.handleSubmit} className='donation-form-settings-container form-container'>
                <div className='dual-grid'>
                    <div className='dual-grid__1-2 form-title'>
                        <h3>Donation Settings</h3>
                    </div>

                    <label>Designations: </label>
                    {designationInput}

                    <label>Designation Order: </label>
                    <select name='designationOrder' value={designationOrder} onChange={this.handleChange} required>
                        <option key='designationOrderSelect' value=''>--Select--</option>
                        <option key='designationOrderAlpha' value='alpha'>Alphabetical</option>
                        <option key='designationOrderTag' value='tag'>Tag</option>
                    </select>

                    <label>Designation Default: </label>
                    <select name='designationDefault' value={designationDefault} onChange={this.handleChange} required>
                        <option key='designationDefaultSelect' value=''>--Select--</option>
                        <option key='designationDefaultSelectOpt' value='select'>Select</option>
                        <option key='designationDefaultLastChosen' value='last_chosen'>Last Chosen (Local Donations Only)</option>
                        <option key='designationDefaultDistinct' value='distinct'>Distinct</option>
                    </select>

                    {designationDistinctDefaultField}

                    <div className='dual-grid__1-2 form-management-btns'>
                        <button type='submit'>Save</button>
                        <a href='/donations' className='delete-btn form-btn'>Cancel</a>
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        const response = await fetch('Settings/GetDonationFormSettings', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        await currentCompanyServices.HandleMissingCompanyAsync(data.currentCompany);

        this.setState({
            donationFormSettings: data.donationFormSettings
        });
    }
}
