import React, { Component } from 'react';
import './forms.css';
import Cookie from "js-cookie";
import currentCompanyServices from './CurrentCompanyServices';

export class LocalDonation extends Component {
    static displayName = LocalDonation.name;

    constructor(props) {
        super(props);
        this.state = {
            localTransaction: null,
            donationFormSettings: null,
            setDefaultTimestamp: true,
            previousDonators: [],
            previousDonatorSelection: '',
            splitDesignations: null,
            fullAmount: 0
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handlePreviousDonatorSelection = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        let localTransaction = this.state.localTransaction;

        if (name == "previousDonator") {
            if (value == "" || value == "newDonator") {
                localTransaction['firstName'] = null;
                localTransaction['lastName'] = null;
                localTransaction['email'] = null;
            }
            else {
                let firstName = e.target[e.target.selectedIndex].getAttribute('data-firstname');
                let lastName = e.target[e.target.selectedIndex].getAttribute("data-lastname");
                let email = e.target[e.target.selectedIndex].getAttribute("data-email");

                localTransaction['firstName'] = firstName;
                localTransaction['lastName'] = lastName;
                localTransaction['email'] = email;
            }
        }

        this.setState({
            previousDonatorSelection: value,
            localTransaction: localTransaction
        });
    }

    handleSplitDesignationChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        let splitDesignations = this.state.splitDesignations;

        if (name == "amount2" || name == "amount3" || name == "amount4" || name == "amount5") {
            splitDesignations[name] = value * 100;
        }
        else {
            splitDesignations[name] = value;
        }

        this.setState({
            splitDesignations: splitDesignations
        });
    }

    handleFullAmountChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        let fullAmount = this.state.fullAmount;
        let lastFullAmount = fullAmount;
        if (name == "fullAmount") {
            fullAmount = value * 100;
        }

        let newLocalTransaction = this.state.localTransaction;
        if (newLocalTransaction.amount == 0 || newLocalTransaction.amount == lastFullAmount) {
            newLocalTransaction.amount = fullAmount;
        }

        this.setState({
            fullAmount: fullAmount,
            localTransaction: newLocalTransaction
        });
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        let newLocalTransaction = this.state.localTransaction;

        if (name == "amount") {
            newLocalTransaction[name] = value * 100;
        }
        else {
            newLocalTransaction[name] = value;
        }

        let setDefaultTimestamp = this.state.setDefaultTimestamp;
        if (name == "timestamp") {
            setDefaultTimestamp = false;
        }
        
        this.setState({
            localTransaction: newLocalTransaction,
            setDefaultTimestamp: setDefaultTimestamp
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let localTransaction = this.state.localTransaction;
        let splitDesignations = this.state.splitDesignations;

        // Add non user data
        localTransaction.status = "live";

        let localTransactionData = {
            localTransaction: localTransaction,
            splitDesignations: splitDesignations
        }

        if (this.state.fullAmount == (localTransaction.amount + splitDesignations.amount2 + splitDesignations.amount3 + splitDesignations.amount4 + splitDesignations.amount5)) {
            fetch('LocalTransaction/CreateLocalTransaction', {
                method: 'POST',
                body: JSON.stringify(localTransactionData),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(
                response => response.json()
            ).then(
                data => {
                    if (!isNaN(data)) {
                        // Set last designation
                        var d = new Date();
                        d.setTime(d.getTime() + (7 * (24 * 60 * 60 * 1000)))//90 Days -> (days * milliseconds per day)
                        var expires = "expires=" + d.toUTCString();
                        document.cookie = "LastChosenDesignation=" + localTransaction.description + ";" + expires + ";path=/";

                        window.location = "/LocalDonation";
                    }
                    else {
                        currentCompanyServices.HandleMissingCompanyAsync(data);
                        alert(data);
                    }
                }
            );
        }
        else {
            alert("Full amount does not equal the sum of the individual amounts.");
        }
    }

    isSplitAmountRequired(designation) {
        let isRequired = designation != '' && designation != null ? true : false;
        //console.log("isSplitAmountRequired", designation, isRequired);
        return isRequired;
    }

    isSplitDesignationRequired(amount) {
        let isRequired = amount != '' && amount != null && amount > 0 ? true : false;
        //console.log("isSplitDesignationRequired", amount, isRequired);
        return isRequired;
    }

    render() { 
        let previousDonators = this.state.previousDonators;

        let firstName;
        let lastName;
        let email;
        let type;
        let description;
        let commemorative;
        let comments;
        let amount;
        let reference;
        let timestampFormat;

        if (this.state.localTransaction != null) {
            let localTransaction = this.state.localTransaction;
            firstName = localTransaction.firstName;
            lastName = localTransaction.lastName;
            email = localTransaction.email;
            type = localTransaction.type;
            description = localTransaction.description;
            commemorative = localTransaction.commemorative;
            comments = localTransaction.comments;
            reference = localTransaction.reference;

            timestampFormat = localTransaction.timestamp;
            if (this.state.setDefaultTimestamp) {
                let timestamp = new Date();
                let month = timestamp.getMonth().toString().length === 1 ? '0' + (timestamp.getMonth() + 1).toString() : timestamp.getMonth() + 1;
                let day = (timestamp.getDate()).toString().length === 1 ? '0' + (timestamp.getDate()).toString() : timestamp.getDate();
                let year = timestamp.getFullYear();
                timestampFormat = year + "-" + month + "-" + day;
            }

            amount = localTransaction.amount;
            amount = amount / 100;
        }

        let descriptionOptions = [];
        if (this.state.donationFormSettings != null) {
            let donationFormSettings = this.state.donationFormSettings;
            let designations = (donationFormSettings.designations != null && donationFormSettings.designations != "") ? donationFormSettings.designations.split(",") : [];
            designations.unshift("Donation");

            if (donationFormSettings.designationOrder == "alpha") {
                designations.sort();
            }

            for (var i = 0; i < designations.length; i++) {
                let descriptionKey = 'description' + i;
                descriptionOptions.push(<option key={descriptionKey} value={designations[i]}>{designations[i]}</option>);
            }
        }
        let descriptionField = <select name='description' value={description} onChange={this.handleChange} required>
            <option key='descriptionSelect' value=''>--Select--</option>
            {descriptionOptions}
        </select>;

        let previousDonatorOpts = [];
        previousDonatorOpts.push(<option key='selectDonator' value=''>--Select--</option>);
        previousDonatorOpts.push(<option key='newDonatorOpt' value='newDonator'>New Donator</option>);
        for (var i = 0; i < previousDonators.length; i++) {
            let donator = previousDonators[i];
            let dLastName = donator.lastName;
            let dFirstName = donator.firstName;
            let dEmail = donator.email == null ? "" : donator.email;
            let opt = <option key={i} value={dLastName + ", " + dFirstName + " " + dEmail} data-lastname={dLastName} data-firstname={dFirstName} data-email={dEmail}>
                {dLastName + ", " + dFirstName + " " + dEmail}
            </option>;
            previousDonatorOpts.push(opt);
        }

        let newDonatorFields = this.state.previousDonatorSelection == 'newDonator' ? <>
            <label>Last Name: </label>
            <input name='lastName' type='text' defaultValue={lastName} onChange={this.handleChange} />

            <label>First Name: </label>
            <input name='firstName' type='text' defaultValue={firstName} onChange={this.handleChange} />

            <label>Email: </label>
            <input name='email' type='email' defaultValue={email} onChange={this.handleChange} />
        </> : "";

        let amount2;
        let designation2;
        let amount3;
        let designation3;
        let amount4;
        let designation4;
        let amount5;
        let designation5;
        if (this.state.splitDesignations != null) {
            let splitDesignations = this.state.splitDesignations;
            amount2 = splitDesignations.amount2 == 0 ? "" : splitDesignations.amount2;
            designation2 = splitDesignations.designation2;
            amount3 = splitDesignations.amount3 == 0 ? "" : splitDesignations.amount3;
            designation3 = splitDesignations.designation3;
            amount4 = splitDesignations.amount4 == 0 ? "" : splitDesignations.amount4;
            designation4 = splitDesignations.designation4;
            amount5 = splitDesignations.amount5 == 0 ? "" : splitDesignations.amount5;
            designation5 = splitDesignations.designation5;
        }

        return (
            <form onSubmit={this.handleSubmit} className='local-donation-container form-container'>
                <div className='dual-grid'>
                    <div className='dual-grid__1-2 form-title'>
                        <h3>Local Donation</h3>
                    </div>

                    <label>Donator: </label>
                    <select name='previousDonator' value={this.state.previousDonatorSelection} onChange={this.handlePreviousDonatorSelection} required>
                        {previousDonatorOpts}
                    </select>

                    {newDonatorFields}

                    <label>Type: </label>
                    <select name='type' value={type} onChange={this.handleChange} required>
                        <option key='typeSelect' value=''>--Select--</option>
                        <option key='typeCash' value='Cash'>Cash</option>
                        <option key='typeCheck' value='Check'>Check</option>
                        <option key='typeBankTransfer' value='BankTransfer'>Bank Transfer</option>
                    </select>

                    <label>Check/Reference: </label>
                    <input name='reference' type='text' defaultValue={reference} onChange={this.handleChange} />

                    <label>Full Amount: </label>
                    <input name='fullAmount' type='number' min='0.01' step='0.01' defaultValue={this.state.fullAmount} onChange={this.handleFullAmountChange} required />

                    <div className='field-by-field-container'>
                        <label>Amount: </label>
                        <input name='amount' type='number' min='0.01' step='0.01' defaultValue={amount} onChange={this.handleChange} required />

                        <label>Designation: </label>
                        {descriptionField}

                        {/*Other Designations*******************************************************************************************/}

                        <label>Amount: </label>
                        <input name='amount2' type='number' min={this.isSplitAmountRequired(designation2) ? '0.01' : '0'} step='0.01' defaultValue={amount2} onChange={this.handleSplitDesignationChange} required={this.isSplitAmountRequired(designation2)} />

                        <label>Designation: </label>
                        <select name='designation2' value={designation2} onChange={this.handleSplitDesignationChange} required={this.isSplitDesignationRequired(amount2)}>
                            <option key='designation2Select' value=''>--Select--</option>
                            {descriptionOptions}
                        </select>

                        <label>Amount: </label>
                        <input name='amount3' type='number' min={this.isSplitAmountRequired(designation3) ? '0.01' : '0'} step='0.01' defaultValue={amount3} onChange={this.handleSplitDesignationChange} required={this.isSplitAmountRequired(designation3)} />

                        <label>Designation: </label>
                        <select name='designation3' value={designation3} onChange={this.handleSplitDesignationChange} required={this.isSplitDesignationRequired(amount3)}>
                            <option key='designation3Select' value=''>--Select--</option>
                            {descriptionOptions}
                        </select>

                        <label>Amount: </label>
                        <input name='amount4' type='number' min={this.isSplitAmountRequired(designation4) ? '0.01' : '0'} step='0.01' defaultValue={amount4} onChange={this.handleSplitDesignationChange} required={this.isSplitAmountRequired(designation4)} />

                        <label>Designation: </label>
                        <select name='designation4' value={designation4} onChange={this.handleSplitDesignationChange} required={this.isSplitDesignationRequired(amount4)}>
                            <option key='designation4Select' value=''>--Select--</option>
                            {descriptionOptions}
                        </select>

                        <label>Amount: </label>
                        <input name='amount5' type='number' min={this.isSplitAmountRequired(designation5) ? '0.01' : '0'} step='0.01' defaultValue={amount5} onChange={this.handleSplitDesignationChange} required={this.isSplitAmountRequired(designation5)} />

                        <label>Designation: </label>
                        <select name='designation5' value={designation5} onChange={this.handleSplitDesignationChange} required={this.isSplitDesignationRequired(amount5)}>
                            <option key='designation5Select' value=''>--Select--</option>
                            {descriptionOptions}
                        </select>

                        {/**************************************************************************************************************/}

                    </div>

                    <label>Timestamp: </label>
                    <input name='timestamp' type='date' defaultValue={timestampFormat} onChange={this.handleChange} />

                    <label>Commemorative: </label>
                    <input name='commemorative' type='text' defaultValue={commemorative} onChange={this.handleChange} />

                    <label>Comments: </label>
                    <textarea name='comments' rows="4" cols="50" defaultValue={comments} onChange={this.handleChange} />

                    <div className='dual-grid__1-2 form-management-btns'>
                        <button type='submit'>Save</button>
                        <a href='/donations' className='delete-btn form-btn'>Cancel</a>
                    </div>
                </div>
            </form>  
        );
    }

    async getComponentData() {
        const response = await fetch('LocalTransaction/GetLocalTransaction', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        await currentCompanyServices.HandleMissingCompanyAsync(data.currentCompany);

        let localTransaction = data.localTransaction;
        let donationFormSettings = data.donationFormSettings;

        //Set default designation
        if (donationFormSettings != null) {
            if (donationFormSettings.designationDefault == "select") {

            }
            else if (donationFormSettings.designationDefault == "last_chosen") {
                let lastChosenDesignation = Cookie.get("LastChosenDesignation");
                if (lastChosenDesignation != null && lastChosenDesignation != "" && lastChosenDesignation != undefined) {
                    if ((lastChosenDesignation == "Donation") || donationFormSettings.designations.includes(lastChosenDesignation)) {
                        localTransaction.description = lastChosenDesignation;
                    }
                }
            }
            else if (donationFormSettings.designationDefault == "distinct") { 
                if ((donationFormSettings.designationDistinctDefault == "Donation") || donationFormSettings.designations.includes(donationFormSettings.designationDistinctDefault)) {
                    localTransaction.description = donationFormSettings.designationDistinctDefault;
                }
            }
        }

        //Set default timestamp
        localTransaction.timestamp = new Date();

        localTransaction.type = "Check";

        this.setState({
            localTransaction: localTransaction,
            donationFormSettings: donationFormSettings,
            previousDonators: data.previousDonators,
            splitDesignations: data.splitDesignations
        });
    }
}
