import React, { Component } from 'react';
import { compareAsc, format, parseISO, compareDesc, parse as parseDate } from 'date-fns';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './donations.css';

export class Subscriptions extends Component {
    static displayName = Subscriptions.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            subscriptions: [],
            columns: [
                {
                    headerName: "First Name",
                    valueGetter: function (params) {
                        return params.data.firstName
                    },
                    sortable: true,
                    filter: true
                },
                {
                    headerName: "Last Name",
                    valueGetter: function (params) {
                        return params.data.lastName
                    },
                    sortable: true,
                    filter: true
                },
                {
                    headerName: "Email",
                    valueGetter: function (params) {
                        return params.data.email
                    },
                    sortable: true,
                    filter: true
                },
                {
                    headerName: "Amount",
                    valueGetter: function (params) {
                        return "$" + (parseInt(params.data.amount) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    },
                    sortable: true,
                    filter: true
                },
                {
                    headerName: "Created",
                    valueGetter: function (params) {
                        return format(parseISO(params.data.created), "MM-dd-yyyy");
                    },
                    sortable: true,
                    comparator: this.dateCompare,
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        comparator: function (filterLocalDateAtMidnight, cellValue) {
                            var dateAsString = cellValue;

                            if (dateAsString == null) {
                                return 0;
                            }
                            //console.log(filterLocalDateAtMidnight);
                            // In the example application, dates are stored as dd/mm/yyyy
                            // We create a Date object for comparison against the filter date
                            var dateParts = dateAsString.split('-');
                            var day = Number(dateParts[2]);
                            var month = Number(dateParts[1]) - 1;
                            var year = Number(dateParts[0]);
                            var cellDate = parseDate(cellValue, 'MM-dd-yyyy', new Date());
                            //console.log(cellDate);
                            // Now that both parameters are Date objects, we can compare
                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            } else {
                                return 0;
                            }
                        },
                        browserDatePicker: true,
                        applyButton: true,
                        clearButton: true,
                        resetButton: true,
                        inRangeInclusive: true,
                        defaultOption: "inRange",
                        filterOptions: ["inRange"]
                    }
                },
                {
                    headerName: "Next Cycle",
                    valueGetter: function (params) {
                        return format(parseISO(params.data.nextCycle), "MM-dd-yyyy");
                    },
                    sortable: true,
                    comparator: this.dateCompare,
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        comparator: function (filterLocalDateAtMidnight, cellValue) {
                            var dateAsString = cellValue;

                            if (dateAsString == null) {
                                return 0;
                            }
                            //console.log(filterLocalDateAtMidnight);
                            // In the example application, dates are stored as dd/mm/yyyy
                            // We create a Date object for comparison against the filter date
                            var dateParts = dateAsString.split('-');
                            var day = Number(dateParts[2]);
                            var month = Number(dateParts[1]) - 1;
                            var year = Number(dateParts[0]);
                            var cellDate = parseDate(cellValue, 'MM-dd-yyyy', new Date());
                            //console.log(cellDate);
                            // Now that both parameters are Date objects, we can compare
                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            } else {
                                return 0;
                            }
                        },
                        browserDatePicker: true,
                        applyButton: true,
                        clearButton: true,
                        resetButton: true,
                        inRangeInclusive: true,
                        defaultOption: "inRange",
                        filterOptions: ["inRange"]
                    }
                },
                {
                    headerName: "Card Expiration",
                    valueGetter: function (params) {
                        return params.data.cardExpMonth + "/" + params.data.cardExpYear
                    },
                    sortable: true,
                    filter: true
                },
                {
                    headerName: "",
                    cellRendererFramework: (params) => {
                        return <div className='manage-btn-container'><p className='delete-btn-link' onClick={this.CancelSubscription.bind(this, params.data.subscriptionId)}>Cancel</p></div>;
                    },
                    sortable: false,
                    filter: false
                }
            ]
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    /* Do stuff external to ag-grid when the filter changes */
    onFilterChanged = () => {
        
    }

    onColumnResized(params) {
        params.api.resetRowHeights();
    }

    saveAPI = (params) => { //Assigns AG Grid APIs to properties for further calls.
        this.api = params.api;
        this.columnApi = params.columnApi;
        this.api.sizeColumnsToFit();
        this.gridApi = params.api;
    }

    render() {
        if (this.state.loading) {
            return (
                <p><em>Loading...</em></p>
            );
        }
        else {
            return (
                <div className='subscriptions-container ag-theme-balham donations-container' style={{ height: "100%", width: "100%" }}>
                    <AgGridReact
                        onGridReady={this.saveAPI}
                        pagination="true"
                        paginationPageSize="25"
                        rowDataChangeDetectionStrategy='IdentityCheck'
                        domLayout='autoHeight'
                        columnDefs={this.state.columns}
                        rowData={this.state.subscriptions}
                        onColumnResized={this.onColumnResized}
                        onFilterChanged={this.onFilterChanged}
                    >
                    </AgGridReact>
                </div>
            );
        }
    }

    async getComponentData() {
        const response = await fetch('Subscription/GetAllSubscriptions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();
        
        this.setState({
            loading: false,
            subscriptions: data
        });
    }

    async CancelSubscription(subscriptionId) {
        let confirmCancel = window.confirm("Are you sure you want to cancel this subscription?");

        if (confirmCancel) {
            const response = await fetch('Subscription/CancelSubscription?subscriptionId=' + subscriptionId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            const data = await response.json();

            if (data != "Canceled") {
                alert(data);
            }

            await this.getComponentData();
        }
    }
}