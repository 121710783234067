import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import Cookie from 'js-cookie';
import { GetLoggedInUserCompany } from './Authentication';
import { Home } from './Home';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import currentCompanyServices from './CurrentCompanyServices';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor (props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            loggedInUserCompany: null,
            hasCurrentCompany: false
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    toggleNavbar () {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        let userCompany = this.state.loggedInUserCompany != null ? this.state.loggedInUserCompany : "TSDonate";

        let homeBtn;
        let localDonationsBtn;
        let settingsBtn;
        let subscriptionsBtn;
        let marketingEmailBtn;
        let imageModuleBtn;

        // Set logged in nav buttons
        if (Cookie.get("AuthCookie") != null && this.state.hasCurrentCompany == true) {
            homeBtn = <NavbarBrand tag={Link} to="/donations">{userCompany}</NavbarBrand>;

            localDonationsBtn = <NavItem>
                <NavLink tag={Link} className="text-dark" to="/LocalDonation">Local Donation</NavLink>
            </NavItem>;
            
            settingsBtn = <NavItem>
                <NavLink tag={Link} className="text-dark" to="/DonationFormSettings">
                    <FontAwesomeIcon icon={faCog} />
                </NavLink>
            </NavItem>;

            subscriptionsBtn = <NavItem>
                <NavLink tag={Link} className="text-dark" to="/Subscriptions">Subscriptions</NavLink>
            </NavItem>;

            marketingEmailBtn = <NavItem>
                <NavLink tag={Link} className='text-dark' to="/marketingemail">Marketing</NavLink>
            </NavItem>;

            imageModuleBtn = <NavItem>
                <NavLink tag={Link} className='text-dark' to="/imagemodule">Images</NavLink>
            </NavItem>;
        }
        else {
            homeBtn = <NavbarBrand>{userCompany}</NavbarBrand>;
        }

        return (
            <header className='nav-menu-container'>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        {homeBtn}
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                {subscriptionsBtn}
                                {localDonationsBtn}
                                {marketingEmailBtn}
                                {imageModuleBtn}
                                {settingsBtn}
                                <LogOutButton/>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    async getComponentData() {
        let loggedInUserCompany = await GetLoggedInUserCompany();
        let hasCurrentCompany = await currentCompanyServices.HasCurrentCompanyAsync();

        this.setState({
            loggedInUserCompany: loggedInUserCompany,
            hasCurrentCompany: hasCurrentCompany
        });
    }
}


class LogOutButton extends Component {
    static displayName = LogOutButton.name;

    constructor (props) {
        super(props);
    }

    async logOut() {
        await currentCompanyServices.RemoveTracSoftCompanyAsync();

        Cookie.remove("AuthCookie");
        window.location.replace("/")
    }

    render() {
        if (Cookie.get("AuthCookie") != null) {
            return <div className="text-dark" style={{alignSelf: "center", cursor: "pointer"}} onClick={this.logOut}>Logout</div>
        }
        else {
            return <div></div>
        }
    }
}