import React, { Component } from 'react';
import './forms.css';
import currentCompanyServices from './CurrentCompanyServices';

export class LocalDonation_Edit extends Component {
    static displayName = LocalDonation_Edit.name;

    constructor(props) {
        super(props);
        this.state = {
            localTransactionId: this.props.match.params.id,
            localTransaction: null,
            donationFormSettings: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let localTransaction = this.state.localTransaction;

        if (name == "amount") {
            localTransaction[name] = value * 100;
        }
        else {
            localTransaction[name] = value;
        }

        this.setState({
            localTransaction: localTransaction
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let localTransaction = this.state.localTransaction;

        fetch('LocalTransaction/UpdateLocalDonation', {
            method: 'POST',
            body: JSON.stringify(localTransaction),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    window.location = "/donations";
                }
                else {
                    currentCompanyServices.HandleMissingCompanyAsync(data);
                    alert(data);
                }
            }
        );
    }

    render() {
        let firstName;
        let lastName;
        let email;
        let type;
        let description;
        let commemorative;
        let comments;
        let amount;
        let reference;
        let timestampFormat;

        if (this.state.localTransaction != null) {
            let localTransaction = this.state.localTransaction;
            firstName = localTransaction.firstName;
            lastName = localTransaction.lastName;
            email = localTransaction.email;
            type = localTransaction.type;
            description = localTransaction.description;
            commemorative = localTransaction.commemorative;
            comments = localTransaction.comments;
            reference = localTransaction.reference;
            
            let timestamp = new Date(localTransaction.timestamp);
            let month = timestamp.getMonth().toString().length === 1 ? '0' + (timestamp.getMonth() + 1).toString() : timestamp.getMonth() + 1;
            let day = (timestamp.getDate()).toString().length === 1 ? '0' + (timestamp.getDate()).toString() : timestamp.getDate();
            let year = timestamp.getFullYear();
            timestampFormat = year + "-" + month + "-" + day;

            amount = localTransaction.amount;
            amount = amount / 100;
        }

        let descriptionOptions = [];
        if (this.state.donationFormSettings != null) {
            let donationFormSettings = this.state.donationFormSettings;
            let designations = (donationFormSettings.designations != null && donationFormSettings.designations != "") ? donationFormSettings.designations.split(",") : [];
            designations.unshift("Donation");

            if (donationFormSettings.designationOrder == "alpha") {
                designations.sort();
            }

            for (var i = 0; i < designations.length; i++) {
                let descriptionKey = 'description' + i;
                descriptionOptions.push(<option key={descriptionKey} value={designations[i]}>{designations[i]}</option>);
            }
        }

        return (
            <form onSubmit={this.handleSubmit} className='local-donation-edit-container form-container'>
                <div className='dual-grid'>
                    <div className='dual-grid__1-2 form-title'>
                        <h3>Edit Local Donation</h3>
                    </div>

                    <label>Last Name: </label>
                    <input name='lastName' type='text' defaultValue={lastName} onChange={this.handleChange} />

                    <label>First Name: </label>
                    <input name='firstName' type='text' defaultValue={firstName} onChange={this.handleChange} />

                    <label>Email: </label>
                    <input name='email' type='email' defaultValue={email} onChange={this.handleChange} />

                    <label>Type: </label>
                    <select name='type' value={type} onChange={this.handleChange} required>
                        <option key='typeSelect' value=''>--Select--</option>
                        <option key='typeCash' value='Cash'>Cash</option>
                        <option key='typeCheck' value='Check'>Check</option>
                        <option key='typeBankTransfer' value='BankTransfer'>Bank Transfer</option>
                    </select>

                    <label>Designation: </label>
                    <select name='description' value={description} onChange={this.handleChange} required>
                        <option key='descriptionSelect' value=''>--Select--</option>
                        {descriptionOptions}
                    </select>

                    <label>Commemorative: </label>
                    <input name='commemorative' type='text' defaultValue={commemorative} onChange={this.handleChange} />

                    <label>Comments: </label>
                    <textarea name='comments' rows="4" cols="50" defaultValue={comments} onChange={this.handleChange} />

                    <label>Check/Reference: </label>
                    <input name='reference' type='text' defaultValue={reference} onChange={this.handleChange} />

                    <label>Amount: </label>
                    <input name='amount' type='number' min='0.01' step='0.01' defaultValue={amount} onChange={this.handleChange} required />

                    <label>Timestamp: </label>
                    <input name='timestamp' type='date' defaultValue={timestampFormat} onChange={this.handleChange} />

                    <div className='dual-grid__1-2 form-management-btns'>
                        <button type='submit'>Save</button>
                        <a href='/donations' className='delete-btn form-btn'>Cancel</a>
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        const response = await fetch('LocalTransaction/GetLocalDonationById?id=' + this.state.localTransactionId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        await currentCompanyServices.HandleMissingCompanyAsync(data.currentCompany);

        let localTransaction = data.localTransaction;
        let donationFormSettings = data.donationFormSettings;

        if (localTransaction == null) {
            window.location = "/donations";
        }

        this.setState({
            localTransaction: localTransaction,
            donationFormSettings: donationFormSettings
        });
    }
}
